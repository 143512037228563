import React from "react";
import { BaseComponent } from "../../../utils/BaseComponent";
import { DataSourceParentStateGeneric, DataSource } from "../../../datasource/base/DataSource.base";
import {
    EndpointCatalogueCategoryListResponse,
    endpointCatalogueCategoryList,
    EndpointCatalogueCategoryList
} from "../../../datasource/catalogue/CategoryList";
import { EndpointCatalogueProductListResponse, endpointCatalogueProductList, EndpointCatalogueProductList } from "../../../datasource/catalogue/ProductList";

import { CatalogueCategoryTree } from "./CategoryTree";
import { Products } from "./Products";

import "./ProductList.scss";
import { withRouter, RouteComponentProps } from "react-router";
import { queryToObject } from "../../../utils/UrlQueryParser";
import { getQueryObject } from "./ProductList.util";

import T from "./ProductList.json";

export interface ProductListDatasources {
    productList: DataSourceParentStateGeneric<EndpointCatalogueProductListResponse>;
}

export interface ProductListProps {
    categoryList: DataSourceParentStateGeneric<EndpointCatalogueCategoryListResponse>;
}

export interface OverlayData {
    show: boolean;
    title?: string;
    text?: string;
}

interface ProductListState {
    datasource: ProductListDatasources;
    selected: {
        main: number | undefined;
        secondary: number | undefined;
        recommended: boolean;
    };
}

export class ProductList extends BaseComponent<RouteComponentProps & ProductListProps, ProductListState> {
    state: ProductListState = {
        datasource: {
            productList: {
                data: [],
                status: "idle"
            }
        },
        selected: {
            main: undefined,
            secondary: undefined,
            recommended: false
        }
    };

    private epProductList = endpointCatalogueProductList();
    private dsProductList = new DataSource<EndpointCatalogueProductList, ProductListDatasources, ProductListState, ProductList>(
        this,
        "productList",
        this.epProductList
    );

    componentDidMount() {
        console.log("compoent mounted");
        this.updateStateByLocation();
    }

    componentDidUpdate() {
        this.updateStateByLocation();
    }

    get overlayData(): OverlayData {
        if (this.props.categoryList.status === "pending" || this.props.categoryList.status === "idle") {
            return {
                show: true,
                title: this.T(T.loadingCategories)
            };
        }
        if (this.props.categoryList.status === "error") {
            return {
                show: true,
                title: this.T(T.errorCategories)
            };
        }

        if (this.state.datasource.productList.status === "pending") {
            return {
                show: true,
                title: this.T(T.loadingProducts)
            };
        }

        if (this.state.datasource.productList.status === "idle") {
            return {
                show: true,
                title: this.T(T.selectCategory)
            };
        }

        if (this.state.datasource.productList.status === "error") {
            return {
                show: true,
                title: this.T(T.errorProducts)
            };
        }

        if (this.state.datasource.productList.status === "ready" && this.state.datasource.productList.data.length === 0) {
            return {
                show: true,
                title: this.T(T.productsEmpty)
            };
        }
        return {
            show: false
        };
    }

    get categoryList() {
        if (this.props.categoryList.status === "ready") {
            return this.props.categoryList.data.filter(e => e.categoryType === 1);
        }
        return [];
    }

    private getRecommended() {
        this.getProducts(6);
    }

    private getProducts(categoryId: number) {
        return this.dsProductList.makeRequest({ params: { categoryId: categoryId } });
    }

    private openMainCategory(categoryId: number | undefined) {
        const category = this.props.categoryList.data.find(e => e.categoryId === categoryId);

        if (!!category) {
            if (category.categories.length === 1) {
                this.props.history.push({
                    pathname: this.props.location.pathname,
                    search: `MainCategoryId=${categoryId}&CategoryId=${category.categories[0].categoryId}`
                });
                return;
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `MainCategoryId=${this.state.selected.main === categoryId ? undefined : categoryId}&CategoryId=${this.state.selected.secondary}`
        });
    }

    private openSecondaryCategory(categoryId: number | undefined, mainCategoryId?: number) {
        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `MainCategoryId=${!!mainCategoryId ? mainCategoryId : this.state.selected.main}&CategoryId=${categoryId}`
        });

        const productList = document.getElementById("productList");

        if (!!productList) {
            window.scrollTo({ top: productList.offsetTop - 80, behavior: "smooth" });
        }
    }

    private setRecommended() {
        console.log("set Recommended");
        this.props.history.push({ pathname: this.props.location.pathname });
    }

    render() {
        return (
            <div className="_catalogue-product-list">
                <div className="_catalogue-product-list__center">
                    <div className="_catalogue-product-list__title">{this.T(T.titleAccessories)}</div>
                    <div className="_catalogue-product-list__categories">
                        <CatalogueCategoryTree
                            categoryType={1}
                            categoryList={this.categoryList}
                            selected={this.state.selected}
                            T={v => this.T(v)}
                            openMain={c => this.openMainCategory(c)}
                            openSecondary={(c, m) => this.openSecondaryCategory(c, m)}
                            setRecomended={() => this.setRecommended()}
                        />
                    </div>
                    <Products
                        productList={this.state.datasource.productList.data}
                        T={v => this.T(v)}
                        order={this.context.order}
                        historyGo={route => this.props.history.push(route)}
                        overlayData={this.overlayData}
                    />
                </div>
            </div>
        );
    }

    private updateStateByLocation() {
        console.log("update state by location");
        const query = getQueryObject(this.props.location.search);

        if (!query.CategoryId) {
            console.log("no categoru if");
            if (!this.state.selected.recommended) {
                this.setState({ selected: { main: query.CategoryId, secondary: undefined, recommended: true } }, () => {
                    this.getRecommended();
                });
                return;
            } else {
                if (query.MainCategoryId !== this.state.selected.main) {
                    this.setState(p => ({ selected: { ...p.selected, main: query.MainCategoryId } }));
                }
            }
            return;
        }

        if (query.CategoryId !== this.state.selected.secondary || query.MainCategoryId !== this.state.selected.main) {
            const shouldReload = query.CategoryId !== this.state.selected.secondary;

            console.log("should realod: ", shouldReload);

            this.setState({ selected: { main: query.MainCategoryId, secondary: query.CategoryId, recommended: false } }, () => {
                if (shouldReload) {
                    if (!!query.CategoryId && query.CategoryId > 0) {
                        this.getProducts(query.CategoryId);
                    }
                }
            });
        } else {
            console.log("nothign happend");
            console.dir(this.state.selected);
        }
    }

    private pushHistory(mainCategoryId: number | undefined, categoryId: number | undefined) {
        this.props.history.push(`${this.props.location.pathname}?MainCategoryId=${mainCategoryId}&CategoryId=${categoryId}`);
    }
}

export default withRouter(ProductList);
