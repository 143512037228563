import React from "react";
import { BrowserRouter } from "react-router-dom";

import logo from "./logo.svg";
import "./scss/index.scss";

import { AppContext, AppContextType } from "./context/AppContext";

import Routing from "./areas/Routing";
import { Authorization } from "./services/Authorization";
import { Identity } from "./datasource/authorization/Authorization.types";
import { TranslationLanguage, Translation } from "./services/Language";
import { OrderState, Order } from "./services/Order";

export interface AppState {
    appContext: {
        auth: {
            identity?: Identity;
        };
        lang: {
            language: TranslationLanguage;
        };
        order: OrderState;
    };
}

export default class App extends React.Component<{}, AppState> {
    state: AppState = {
        appContext: {
            auth: {
                identity: undefined
            },
            lang: {
                language: "pl"
            },
            order: {
                products: [],
                isOpen: false
            }
        }
    };

    public appContext: AppContextType = {
        auth: new Authorization(this),
        lang: new Translation(this),
        order: new Order(this)
    };

    componentDidMount() {
        this.appContext.order.getProductsFromLocalStorage();
        this.appContext.auth
            .loadSavedIdentity()
            .then(() => console.log("saved identity loaded"))
            .catch(e => console.log(e));
        this.appContext.lang.loadLanguageFromLocalStorage();
    }

    render() {
        return (
            <AppContext.Provider value={this.appContext}>
                <BrowserRouter>
                    <Routing />
                </BrowserRouter>
            </AppContext.Provider>
        );
    }
}
