import { queryToObject } from "../../../utils/UrlQueryParser";

export interface QueryObject<T> {
    MainCategoryId?: T;
    CategoryId?: T;
}

export function getQueryObject(query: string): QueryObject<number> {
    const queryString = queryToObject<QueryObject<string>>(query);

    const o: QueryObject<number> = {
        MainCategoryId:
            !!queryString.MainCategoryId && Number.isInteger(parseInt(queryString.MainCategoryId))
                ? parseInt(queryString.MainCategoryId)
                : undefined,
        CategoryId:
            !!queryString.CategoryId && Number.isInteger(parseInt(queryString.CategoryId))
                ? parseInt(queryString.CategoryId)
                : undefined
    };

    return o;
}
