import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import ReactMarkdown from "react-markdown";

import { BaseComponent } from "../../../utils/BaseComponent";
import { DataSourceParentStateGeneric, DataSource } from "../../../datasource/base/DataSource.base";
import {
    EndpointCatalogueProductDetailsResponse,
    EndpointCatalogueProductDetails,
    endpointCatalogueProductDetails
} from "../../../datasource/catalogue/ProductDetails";
import { queryToObject } from "../../../utils/UrlQueryParser";

import { CatalogueCategoryTree } from "../ProductList/CategoryTree";

import "./Product.scss";

import T from "./Product.json";

import {
    EndpointCatalogueCategoryListResponse,
    endpointCatalogueCategoryList,
    EndpointCatalogueCategoryList
} from "../../../datasource/catalogue/CategoryList";

export interface ProductListProps {
    categoryList: DataSourceParentStateGeneric<EndpointCatalogueCategoryListResponse>;
}

interface State {
    productId: number | undefined;
    categoryType: number | undefined;
    datasource: ProductDetailsDatasources;
    photoIndex: number;
    selected: {
        main: number | undefined;
        secondary: number | undefined;
    };
    opened: {
        bindingType: number | undefined;
        main: number | undefined;
        secondary: number | undefined;
    };
}

interface ProductDetailsDatasources {
    productDetails: DataSourceParentStateGeneric<EndpointCatalogueProductDetailsResponse | undefined>;
}

class ProductDetails extends BaseComponent<RouteComponentProps & ProductListProps, State> {
    state: State = {
        productId: undefined,
        categoryType: undefined,
        datasource: {
            productDetails: {
                data: undefined,
                status: "idle"
            }
        },
        selected: {
            main: undefined,
            secondary: undefined
        },
        photoIndex: 0,
        opened: {
            bindingType: undefined,
            main: undefined,
            secondary: undefined
        }
    };

    private epProductDetails = endpointCatalogueProductDetails();
    private dsProductDetails = new DataSource<EndpointCatalogueProductDetails, ProductDetailsDatasources, State, ProductDetails>(
        this,
        "productDetails",
        this.epProductDetails
    );

    componentDidMount() {
        this.handleProductIdChange();
    }

    componentDidUpdate() {
        this.handleProductIdChange();
    }

    private handleProductIdChange() {
        const searchParams = new URLSearchParams(this.props.location.search);

        const productId = searchParams.get("ProductId");
        const categoryType = searchParams.get("Type");

        if (!!productId) {
            const productIdInt = parseInt(productId);

            const categoryTypeInt = !!categoryType ? parseInt(categoryType) : undefined;

            if (this.state.productId !== productIdInt) {
                this.setState({ productId: productIdInt, categoryType: categoryTypeInt }, () => this.getProductDetails());
            }
        } else {
            this.props.history.push("/catalogue");
        }
    }

    get data() {
        return this.state.datasource.productDetails.data;
    }

    get categoryType(): 1 | 2 {
        if (this.state.datasource.productDetails.status === "ready" && this.state.datasource.productDetails.data) {
            if (this.state.datasource.productDetails.data.type.productTypeId === 1) {
                return 2;
            } else {
                return 1;
            }
        } else {
            if (this.state.categoryType === 2) {
                return 1;
            } else if (this.state.categoryType === 1) {
                return 2;
            }
        }

        return 1;
    }

    get categoryList() {
        if (this.props.categoryList.status === "ready") {
            return this.props.categoryList.data.filter(e => e.categoryType === this.categoryType);
        }
        return [];
    }

    render() {
        if (this.state.datasource.productDetails.status !== "ready") {
            return (
                <div className="_catalogue-product-list">
                    <div className="_catalogue-product-list__center">
                        <div className="_catalogue-product-list__title">
                            {this.categoryType === 1 ? this.T(T.titleAccessories) : this.categoryType === 2 ? this.T(T.titleDevices) : <>&nbsp;</>}
                        </div>
                        <div className="_catalogue-product-list__categories">
                            <CatalogueCategoryTree
                                categoryType={2}
                                categoryList={this.categoryList}
                                selected={this.state.selected}
                                T={v => this.T(v)}
                                openMain={c => this.openMainCategory(c)}
                                openSecondary={(c, m) => this.openSecondaryCategory(c, m)}
                                setRecomended={() => {}}
                            />
                        </div>
                        <div className="_product-details">
                            <div className="_product-details__status">
                                <div className="_product-details__status-title">
                                    {this.state.datasource.productDetails.status === "error" ? this.T(T.error.title) : this.T(T.loading)}
                                </div>
                                <div className="_product-details__status-description">
                                    {this.state.datasource.productDetails.status === "error" ? this.T(T.error.description) : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const p = this.state.datasource.productDetails.data;
        if (!p) {
            return (
                <div className="_catalogue-product-list">
                    <div className="_catalogue-product-list__center">
                        <div className="_catalogue-product-list__title">
                            {this.categoryType === 1 ? this.T(T.titleAccessories) : this.categoryType === 2 ? this.T(T.titleDevices) : <>&nbsp;</>}
                        </div>
                        <div className="_catalogue-product-list__categories">
                            <CatalogueCategoryTree
                                categoryType={2}
                                categoryList={this.categoryList}
                                selected={this.state.selected}
                                T={v => this.T(v)}
                                openMain={c => this.openMainCategory(c)}
                                openSecondary={(c, m) => this.openSecondaryCategory(c, m)}
                                setRecomended={() => {}}
                            />
                        </div>
                        <div className="_product-details">
                            <div className="_product-details__status">
                                <div className="_product-details__status-title">{this.T(T.error.title)}</div>
                                <div className="_product-details__status-description">{this.T(T.error.description)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        const isInOrder = this.context.order.state.products.map(e => e.productId).includes(p.productId);

        let bindedProducts: any = null;

        if (p.bindedProducts.length > 0) {
            bindedProducts = (
                <div className="_product-details__binded">
                    {p.bindedProducts.map(b => {
                        if (b.categoryTree.length === 0) {
                            return null;
                        }

                        return (
                            <div className="_product-details__binded-type" key={b.productBindingTypeId}>
                                <div
                                    className="_product-details__binded-type-header"
                                    onClick={() => {
                                        this.setState(s => ({
                                            opened: {
                                                ...s.opened,
                                                bindingType: s.opened.bindingType !== b.productBindingTypeId ? b.productBindingTypeId : undefined
                                            }
                                        }));
                                    }}
                                >
                                    <div className="_product-details__binded-type-header-title">{this.T(b.label)}</div>
                                    <div className="_product-details__binded-type-header-icon">
                                        {b.productBindingTypeId === this.state.opened.bindingType ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                                    </div>
                                </div>
                                {b.productBindingTypeId === this.state.opened.bindingType ? (
                                    <div className="_product-details__binded-category-main-list">
                                        {b.categoryTree.map(c => (
                                            <div className="_product-details__binded-category-main-item" key={c.categoryId}>
                                                <div
                                                    className="_product-details__binded-category-main-item-header"
                                                    onClick={() =>
                                                        this.setState(s => ({
                                                            opened: {
                                                                ...s.opened,
                                                                main: s.opened.main !== c.categoryId ? c.categoryId : undefined
                                                            }
                                                        }))
                                                    }
                                                >
                                                    <div className="_product-details__binded-category-main-item-header-title">{this.T(c.label)}</div>
                                                    <div className="_product-details__binded-category-main-item-header-icon">
                                                        {this.state.opened.main === c.categoryId ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                                                    </div>
                                                </div>
                                                {this.state.opened.main === c.categoryId ? (
                                                    <div className="_product-details__binded-category-secondary-list">
                                                        {c.categories.map(cc => (
                                                            <div className="_product-details__binded-category-secondary-item" key={cc.categoryId}>
                                                                <div
                                                                    className="_product-details__binded-category-secondary-item-header"
                                                                    onClick={() =>
                                                                        this.setState(s => ({
                                                                            opened: {
                                                                                ...s.opened,
                                                                                secondary: s.opened.secondary !== cc.categoryId ? cc.categoryId : undefined
                                                                            }
                                                                        }))
                                                                    }
                                                                >
                                                                    <div className="_product-details__binded-category-secondary-item-header-title">
                                                                        {this.T(cc.label)}
                                                                    </div>
                                                                    <div className="_product-details__binded-category-secondary-item-header-icon">
                                                                        {this.state.opened.secondary === cc.categoryId
                                                                            ? "keyboard_arrow_up"
                                                                            : "keyboard_arrow_down"}
                                                                    </div>
                                                                </div>
                                                                {this.state.opened.secondary === cc.categoryId ? (
                                                                    <div className="_product-details__binded-product-list">
                                                                        {cc.products.map(bp => (
                                                                            <div className="_product-details__binded-product-item" key={bp.productId}>
                                                                                <div className="_product-details__binded-product-item-info-name">
                                                                                    {this.T(bp.productName)}
                                                                                </div>
                                                                                <div className="_product-details__binded-product-item-info-ean">{bp.ean}</div>
                                                                                <div className="_product-details__binded-product-item-button">
                                                                                    <div
                                                                                        onClick={() =>
                                                                                            this.props.history.push({
                                                                                                pathname: `/catalogue/product`,
                                                                                                search: `ProductId=${bp.productId}&Type=${bp.productType.productTypeId}`
                                                                                            })
                                                                                        }
                                                                                        className="button"
                                                                                    >
                                                                                        {this.T(T.productShow)}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : null}
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        );
                    })}
                </div>
            );
        }

        return (
            <div className="_catalogue-product-list">
                <div className="_catalogue-product-list__center">
                    <div className="_catalogue-product-list__title">{p.type.productTypeId === 2 ? this.T(T.titleAccessories) : this.T(T.titleDevices)}</div>
                    <div className="_catalogue-product-list__categories">
                        <CatalogueCategoryTree
                            categoryType={2}
                            categoryList={this.categoryList}
                            selected={this.state.selected}
                            T={v => this.T(v)}
                            openMain={c => this.openMainCategory(c)}
                            openSecondary={(c, m) => this.openSecondaryCategory(c, m)}
                            setRecomended={() => {}}
                        />
                    </div>
                    <div className="_product-details">
                        <div className="_product-details__content">
                            <div className="_product-details__photos">
                                {p.photos.length > 0 ? (
                                    <div className="_product-details__photos-photo">
                                        <img
                                            className="_product-details__photos-photo-img"
                                            src={GEAPP_API_CONFIG.photoUrl + p.photos[this.state.photoIndex].filename}
                                            alt=""
                                        />
                                        {p.photos.length > 1 ? (
                                            <div className="_product-details__photos-buttons">
                                                <div className="_product-details__photos-button" onClick={() => this.nextPhoto(-1)}>
                                                    keyboard_arrow_left
                                                </div>
                                                <div className="_product-details__photos-button" onClick={() => this.nextPhoto(1)}>
                                                    keyboard_arrow_right
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                ) : (
                                    <div className="_product-details__photos-no">photo_camera</div>
                                )}
                            </div>

                            <div className="_product-details__order">
                                {isInOrder ? (
                                    <button className="button fill" onClick={() => this.context.order.removeProduct(p.productId)}>
                                        {this.T(T.deleteFromQuery)}
                                    </button>
                                ) : (
                                    <button
                                        className="button"
                                        onClick={() =>
                                            this.context.order.addProduct({
                                                productId: p.productId,
                                                productName: p.productName,
                                                category: { mainLabel: p.mainCategory.label, secodnaryLabel: p.category.label },
                                                productTypeLabel: p.type.label,
                                                ean: p.ean,
                                                photoFilename: p.photos.length > 0 ? p.photos[0].filename : "",
                                                count: 1
                                            })
                                        }
                                    >
                                        {this.T(T.addToQuery)}
                                    </button>
                                )}
                            </div>

                            <div className="_product-details__basic">
                                <div className="_product-details__basic-ean">{p.ean}</div>
                                <div className="_product-details__basic-name">{this.T(p.productName)}</div>
                                <div className="_product-details__basic-type">{this.T(p.type.label)}</div>
                                <div className="_product-details__basic-category">
                                    {this.T(p.mainCategory.label)} > {this.T(p.category.label)}
                                </div>
                            </div>

                            {p.properties.map(prop => {
                                const youtubeDefinition = prop.definitions.find(e => e.productPropertyDefinitionId === 21);
                                const youtubeElement = !!youtubeDefinition ? (
                                    <div className="_product-details__youtube">
                                        <iframe
                                            id="ytplayer"
                                            className="_product-details__youtube-player"
                                            src={`${youtubeDefinition.content.content.en}?autoplay=0`}
                                            frameBorder="0"
                                        />
                                    </div>
                                ) : null;

                                const definitions = prop.definitions.filter(e => e.productPropertyDefinitionId !== 21);

                                if (definitions.length === 0) {
                                    return (
                                        <React.Fragment key={prop.productPropertyGroupId}>
                                            {youtubeElement}
                                            {prop.productPropertyGroupId === 4 ? bindedProducts : null}
                                        </React.Fragment>
                                    );
                                }
                                return (
                                    <React.Fragment key={prop.productPropertyGroupId}>
                                        <div className="_product-details__definition-group" key={prop.productPropertyGroupId}>
                                            <div className="_product-details__definition-group-title">{this.T(prop.label)}</div>
                                            {definitions.map(def => (
                                                <div className="_product-details__definition-item" key={def.productPropertyDefinitionId}>
                                                    <div className="_product-details__definition-item-label">{this.T(def.label)}</div>
                                                    <div className="_product-details__definition-item-content">
                                                        {def.productPropertyDefinitionTypeId === "Text" ? (
                                                            <p>{this.T(def.content.content)}</p>
                                                        ) : (
                                                            <ReactMarkdown source={this.T(def.content.content)} />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {prop.productPropertyGroupId === 4 ? youtubeElement : null}
                                        {prop.productPropertyGroupId === 4 ? bindedProducts : null}
                                    </React.Fragment>
                                );
                            })}

                            {p.files.length > 0 ? (
                                <div className="_catalogue-product__files">
                                    {p.files.reduce((t, fg) => {
                                        const tfg = fg.files.map(f => {
                                            let currentFile = f.localized[this.context.lang.currentLanguage];

                                            if (!currentFile && !f.localized["en"]) {
                                                return null;
                                            }

                                            if (!currentFile && !!f.localized["en"]) {
                                                currentFile = f.localized["en"];
                                            }

                                            if (!currentFile) {
                                                return null;
                                            }

                                            return (
                                                <div className="_catalogue-product__file" key={f.fileId}>
                                                    <a href={GEAPP_API_CONFIG.fileUrl + currentFile.filename}>
                                                        <div className="_catalogue-product__file-preview">
                                                            {currentFile.previewFilename ? (
                                                                <img src={GEAPP_API_CONFIG.photoUrl + currentFile.previewFilename} alt="" />
                                                            ) : (
                                                                <div className="_catalogue-product__file-preview-empty">insert_drive_file</div>
                                                            )}
                                                        </div>
                                                        <div className="_catalogue-product__file-label">{this.T(f.label)}</div>
                                                    </a>
                                                </div>
                                            );
                                        });
                                        return [...t, ...tfg];
                                    }, [] as any[])}
                                </div>
                            ) : null}

                            {/* {p.bindedProducts.length > 0 ? (
                        <div className="_product-details__binded">
                            {p.bindedProducts.map(b => {
                                if (b.categoryTree.length === 0) {
                                    return null;
                                }

                                return (
                                    <div className="_product-details__binded-type" key={b.productBindingTypeId}>
                                        <div
                                            className="_product-details__binded-type-header"
                                            onClick={() => {
                                                this.setState(s => ({
                                                    opened: {
                                                        ...s.opened,
                                                        bindingType:
                                                            s.opened.bindingType !== b.productBindingTypeId
                                                                ? b.productBindingTypeId
                                                                : undefined
                                                    }
                                                }));
                                            }}
                                        >
                                            <div className="_product-details__binded-type-header-title">
                                                {this.T(b.label)}
                                            </div>
                                            <div className="_product-details__binded-type-header-icon">
                                                {b.productBindingTypeId === this.state.opened.bindingType
                                                    ? "keyboard_arrow_up"
                                                    : "keyboard_arrow_down"}
                                            </div>
                                        </div>
                                        {b.productBindingTypeId === this.state.opened.bindingType ? (
                                            <div className="_product-details__binded-category-main-list">
                                                {b.categoryTree.map(c => (
                                                    <div
                                                        className="_product-details__binded-category-main-item"
                                                        key={c.categoryId}
                                                    >
                                                        <div
                                                            className="_product-details__binded-category-main-item-header"
                                                            onClick={() =>
                                                                this.setState(s => ({
                                                                    opened: {
                                                                        ...s.opened,
                                                                        main:
                                                                            s.opened.main !== c.categoryId
                                                                                ? c.categoryId
                                                                                : undefined
                                                                    }
                                                                }))
                                                            }
                                                        >
                                                            <div className="_product-details__binded-category-main-item-header-title">
                                                                {this.T(c.label)}
                                                            </div>
                                                            <div className="_product-details__binded-category-main-item-header-icon">
                                                                {this.state.opened.main === c.categoryId
                                                                    ? "keyboard_arrow_up"
                                                                    : "keyboard_arrow_down"}
                                                            </div>
                                                        </div>
                                                        {this.state.opened.main === c.categoryId ? (
                                                            <div className="_product-details__binded-category-secondary-list">
                                                                {c.categories.map(cc => (
                                                                    <div
                                                                        className="_product-details__binded-category-secondary-item"
                                                                        key={cc.categoryId}
                                                                    >
                                                                        <div
                                                                            className="_product-details__binded-category-secondary-item-header"
                                                                            onClick={() =>
                                                                                this.setState(s => ({
                                                                                    opened: {
                                                                                        ...s.opened,
                                                                                        secondary:
                                                                                            s.opened.secondary !==
                                                                                            cc.categoryId
                                                                                                ? cc.categoryId
                                                                                                : undefined
                                                                                    }
                                                                                }))
                                                                            }
                                                                        >
                                                                            <div className="_product-details__binded-category-secondary-item-header-title">
                                                                                {this.T(cc.label)}
                                                                            </div>
                                                                            <div className="_product-details__binded-category-secondary-item-header-icon">
                                                                                {this.state.opened.secondary ===
                                                                                cc.categoryId
                                                                                    ? "keyboard_arrow_up"
                                                                                    : "keyboard_arrow_down"}
                                                                            </div>
                                                                        </div>
                                                                        {this.state.opened.secondary ===
                                                                        cc.categoryId ? (
                                                                            <div className="_product-details__binded-product-list">
                                                                                {cc.products.map(bp => (
                                                                                    <div
                                                                                        className="_product-details__binded-product-item"
                                                                                        key={bp.productId}
                                                                                    >
                                                                                        <div className="_product-details__binded-product-item-info-name">
                                                                                            {this.T(bp.productName)}
                                                                                        </div>
                                                                                        <div className="_product-details__binded-product-item-info-ean">
                                                                                            {bp.ean}
                                                                                        </div>
                                                                                        <div className="_product-details__binded-product-item-button">
                                                                                            <a
                                                                                                href={`/catalogue/product?ProductId=${bp.productId}`}
                                                                                                className="button"
                                                                                            >
                                                                                                {this.T(T.productShow)}
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null} */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private openMainCategory(categoryId: number | undefined) {
        const category = this.categoryList.find(e => e.categoryId === categoryId);

        this.setState(p => ({ selected: { ...p.selected, main: p.selected.main === categoryId ? undefined : categoryId } }));

        // if (!!category) {
        //     if (category.categories.length === 1) {
        //         this.props.history.push({
        //             pathname: this.categoryType === 2 ? "/devices" : "/accessories",
        //             search: `MainCategoryId=${categoryId}&CategoryId=${category.categories[0].categoryId}`
        //         });
        //         return;
        //     }
        // }

        // this.props.history.push({
        //     pathname: this.categoryType === 2 ? "/devices" : "/accessories",
        //     search: `MainCategoryId=${this.state.selected.main === categoryId ? -1 : categoryId}&CategoryId=${this.state.selected.secondary}`
        // });
    }

    private openSecondaryCategory(categoryId: number | undefined, mainCategoryId?: number) {
        this.props.history.push({
            pathname: this.categoryType === 2 ? "/devices" : "/accessories",
            search: `MainCategoryId=${!!mainCategoryId ? mainCategoryId : this.state.selected.main}&CategoryId=${categoryId}`
        });
    }

    private nextPhoto(direction: 1 | -1) {
        if (!this.data || this.data.photos.length < 2) {
            return;
        }
        let newIndex = this.state.photoIndex + direction;
        if (newIndex < 0) {
            newIndex = this.data.photos.length - 1;
        }
        if (newIndex > this.data.photos.length - 1) {
            newIndex = 0;
        }

        this.setState({ photoIndex: newIndex });
    }

    private async getProductDetails() {
        if (!this.state.productId) {
            return;
        }
        await this.dsProductDetails.makeRequest({ params: { productId: this.state.productId } });

        if (this.state.datasource.productDetails.status === "ready" && !!this.state.datasource.productDetails.data) {
            this.setState({
                selected: {
                    main: this.state.datasource.productDetails.data.mainCategory.categoryId,
                    secondary: this.state.datasource.productDetails.data.category.categoryId
                }
            });
        }
    }
}

export default withRouter(ProductDetails);
