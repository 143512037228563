import React from "react";
import { withRouter, RouteComponentProps } from "react-router";

import { BaseComponent } from "../../utils/BaseComponent";

import Slider from "./Slider";
import Info from "./Info";
import About from "./About";

import "./Start.scss";

interface StartState {
    slider: number;
    instruction: boolean;
}

class Start extends BaseComponent<RouteComponentProps, StartState> {
    private sliderMax = 5;
    private sliderInterval?: number;

    state: StartState = {
        slider: 0,
        instruction: false
    };

    componentDidMount() {
        this.sliderInterval = window.setInterval(() => {
            this.sliderNext(1, true);
        }, 10000);
    }

    render() {
        return (
            <div className="_start">
                <div className="_start__center">
                    <Slider
                        lang={this.context.lang.currentLanguage}
                        current={this.state.slider}
                        next={direction => this.sliderNext(direction)}
                        goTo={index => this.sliderGoTo(index)}
                    />

                    <Info
                        extendedInstruction={this.state.instruction}
                        toggleInstruction={() => this.toggleInstruction()}
                        lang={this.context.lang.currentLanguage}
                        T={t => this.T(t)}
                    />
                    <About T={t => this.T(t)} />
                </div>
            </div>
        );
    }

    private sliderNext(direction: -1 | 1, interval?: boolean) {
        if (!interval) {
            this.stopInterval();
        }
        this.setState(p => {
            let newPosition = p.slider + direction;

            if (newPosition < 0) {
                newPosition = this.sliderMax - 1;
            } else if (newPosition >= this.sliderMax) {
                newPosition = 0;
            }

            return {
                slider: newPosition
            };
        });
    }

    private sliderGoTo(index: number) {
        this.stopInterval();
        if (index >= this.sliderMax) {
            index = this.sliderMax - 1;
        } else if (index < 0) {
            index = 0;
        }

        this.setState({ slider: index });
    }

    private stopInterval() {
        if (!!this.sliderInterval) {
            window.clearInterval(this.sliderInterval);
            this.sliderInterval = undefined;
        }
    }

    private toggleInstruction() {
        this.setState(p => ({ instruction: !p.instruction }));
    }
}

export default withRouter(Start);
