import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';

import {
    ProductListByCategoryItem,
    ProductListByCategoryParams
} from '../../../../../Api/types-client/src/areas/catalogue/Product.types';

export type EndpointCatalogueProductListResponse = ProductListByCategoryItem[];

export type EndpointCatalogueProductListParams = ProductListByCategoryParams;

export class EndpointCatalogueProductList extends Endpoint<
    EndpointCatalogueProductListParams,
    undefined,
    EndpointCatalogueProductListResponse,
    undefined
> {}

export function endpointCatalogueProductList() {
    return new EndpointCatalogueProductList(
        {
            url: '/catalogue/products/list',
            method: 'GET',
            authorizationToken: false
        },
        undefined
    );
}
