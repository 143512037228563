import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';
import { Session, Identity } from './Authorization.types';
import { BaseComponent } from '../../utils/BaseComponent';

export interface EndpointAuthorizationLogoutData {
    identityId: number;
    refreshToken: string;
}

export interface EndpointAuthorizationLogoutResponse {}

export class EndpointAuthorizationLogout extends Endpoint<
    undefined,
    EndpointAuthorizationLogoutData,
    EndpointAuthorizationLogoutResponse,
    undefined
> {}

export function endpointAuthorizationLogout() {
    return new EndpointAuthorizationLogout(
        {
            url: '/authorize/logout',
            method: 'POST',
            authorizationToken: false
        },
        undefined
    );
}
