import React from "react";
import { BaseComponent } from "../utils/BaseComponent";

export default class Rodo extends BaseComponent {
    render() {
        return (
            <div className="_privacy">
                <div className="_privacy__content">
                    <div className="_privacy__title">
                        {this.T({
                            pl: `Klauzula przetwarzania danych osobowych udostępnionych drogą elektroniczną
`,
                            en: `Processing of personal data provided electronically`,
                        })}
                    </div>
                    <div className="_privacy__text">
                        <p>
                            <b>
                                {this.T({
                                    pl: "TOŻSAMOŚĆ ADMINISTRATORA",
                                    en: "ADMINISTRATOR'S IDENTITY",
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Administratorem danych jest Venn Krzysztof Gaik,
                            mający siedzibę w Warszawie (02-681) przy al.
                            Wyścigowej 14 lok. 463 – odpowiada za utrzymanie i
                            rozwój rejestru.`,
                                en: `Data’s administrator is Venn Krzysztof Gaik, with headquarters on al. Wyścigowa 14 apt 463, (02-681) Warsaw - responsible for the maintenance and the development of the register.`,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `DANE KONTAKTOWE ADMINISTRATORA`,
                                    en: `ADMINISTRATOR'S CONTACT DETAILS`,
                                })}
                            </b>
                            <br />{" "}
                            {this.T({
                                pl: `Z administratorem danych można się skontaktować
                            poprzez adres email rodo@venn.pl lub pisemnie na
                            adres siedziby administratora.`,
                                en: `You can contact the administrator via email: rodo@venn.pl or by writing a letter and sending it to the headquarters (address above)
                                `,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `DANE KONTAKTOWE INSPEKTORA OCHRONY DANYCH`,
                                    en: `DATE PROTECTION OFFICER'S CONTACT DETAILS`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Inspektor ochrony danych, z którym może się Pani /
                            Pan skontaktować poprzez email rodo@venn.pl. Z
                            inspektorem ochrony danych można się kontaktować we
                            wszystkich sprawach dotyczących przetwarzania danych
                            osobowych oraz korzystania z praw związanych z
                            przetwarzaniem danych.`,
                                en: `Data Protection Officers, whom you can contact via email: rodo@venn.pl, will answer all questions considering the processing of personal data and exercising of rights related to the processing of data.`,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `CELE PRZETWARZANIA I PODSTAWA PRAWNA`,
                                    en: `THE PURPOSE OF PROCESSING AND LEGAL BASIS`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Strony w domenie http://www.akcesoria-ge.pl/ mogą
                            zawierać adresy skrzynek mailowych. Użytkownik
                            korzystający z odnośnika będącego adresem e-mail
                            zgadza się na przetwarzanie jego danych (adres
                            e-mail oraz dobrowolnie podanych danych w
                            wiadomości) w celu przesłania odpowiedzi na
                            przesłane pytania.`,
                                en: `Sites on the http://www.akcesoria-ge.pl service may contain email addresses. By using the email link, visitor agrees to the processing of his data ( email address and the data inside the message ) in order to send a response to the question previously sent.`,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `ODBIORCY DANYCH`,
                                    en: `DATA RECEIVERS`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Pani / Pana dane osobowe mogą być udostępniane i
                            przekazywane organom administracji publicznej,
                            służbom, sądom i prokuraturze.`,
                                en: `Users personal data can be shared or forwarded to public administrators, services, courts and prosecution.`,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `PRZEKAZANIE DANYCH OSOBOWYCH DO PAŃSTWA
                                TRZECIEGO LUB ORGANIZACJI MIĘDZYNARODOWEJ`,
                                    en: `FORWARDING PERSONAL DATA TO THIRD COUNTRIES OR INTERNATIONAL ORGANIZATIONS`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Nie dotyczy.`,
                                en: `Doesn’t concern `,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `OKRES PRZECHOWYWANIA DANYCH`,
                                    en: `RENTATION PERIOD OF DATA`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Pani / Pana dane będą przetwarzane czasowo, tj. na
                            potrzeby korespondencji.`,
                                en: `Users data will be rented for a certain period of time, for communication purposes`,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `PRAWA PODMIOTÓW DANYCH`,
                                    en: `USERS RIGHTS`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Przysługuje Pani/Panu prawo dostępu do Pani/Pana
                            danych oraz prawo żądania ich sprostowania i
                            usunięcia.`,
                                en: `User has a right of access to data, a right to demand a correction and a right to delete data`,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `PRAWO WNIESIENIA SKARGI DO ORGANU NADZORCZEGO`,
                                    en: `THE RIGHT TO FILLING A COMPLAINT TO SUPERVISORY AUTHORITY`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Przysługuje Pani/Panu prawo wniesienia skargi do
                            organu nadzorczego zajmującego się ochroną danych
                            osobowych w państwie członkowskim Pani / Pana
                            zwykłego pobytu, miejsca pracy lub miejsca
                            popełnienia domniemanego naruszenia.`,
                                en: `The Right To Filling a Complaint to Supervisory Authority 
                                The user has a legal right to fill a complaint to supervisory authority specializing in personal data processing, that’s devoted to users habitual residence, workplace or a place where the alleged infringement happened.
                                `,
                            })}
                            <br />
                            {this.T({
                                pl: `Biuro Prezesa Urzędu Ochrony Danych Osobowych
                            (PUODO)`,
                                en: `Biuro Prezesa Urzędu Ochrony Danych Osobowych
                            (PUODO)`,
                            })}
                            <br />
                            {this.T({
                                pl: `Adres: Stawki 2, 00-193 Warszawa`,
                                en: `Address: Stawki 2, 00-193 Warszawa`,
                            })}
                            <br />
                            {this.T({
                                pl: `Telefon: 22 531 03 00`,
                                en: `Telephone: 22 531 03 00`,
                            })}
                            <br />
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `ŹRÓDŁO POCHODZENIA DANYCH OSOBOWYCH`,
                                    en: `SOURCE OF PERSONAL DATA`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Dane pochodzą od osób których dane dotyczą i zostały
                            udostępnione celem nawiązania kontaktu.`,
                                en: `Personal Data comes from the users, whom the data concerns and were published only to make contact.
                                `,
                            })}
                            <br />
                            <br />
                            <b>
                                {this.T({
                                    pl: `INFORMACJA O DOWOLNOŚCI LUB OBOWIĄZKU PODANIA
                                DANYCH`,
                                    en: `INFORMATION ABOUT FREEDOM OR OBLIGATION OF PROVIDING THE DATA`,
                                })}
                            </b>
                            <br />
                            {this.T({
                                pl: `Podanie danych osobowych było dobrowolne ale
                            warunkowało możliwość podjęcia działań przez
                            administratora danych.`,
                                en: `Providing personal data was voluntary but conditioned taking actions by administrator for the datasource.`,
                            })}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

/* {this.T({pl: ``, en: ``})} */
