import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';
import { Session, Identity } from './Authorization.types';
import { BaseComponent } from '../../utils/BaseComponent';


export interface EndpointAuthorizationCheckSessionResponse {}

export class EndpointAuthorizationCheckSession extends Endpoint<
    undefined,
    undefined,
    EndpointAuthorizationCheckSessionResponse,
    EndpointParentComponent
> {}

export function endpointAuthorizationCheckSession(parent: EndpointParentComponent) {
    return new EndpointAuthorizationCheckSession(
        {
            url: '/authorize/checkSession',
            method: 'GET',
            authorizationToken: true
        },
        parent
    );
}
