import React from "react";
import { Link, useHistory } from "react-router-dom";

import { LocalizedValue } from "../../../services/Language";
import { EndpointCatalogueProductListResponse } from "../../../datasource/catalogue/ProductList";

import t from "./ProductList.json";
import { OrderProduct, Order } from "../../../services/Order";
import { OverlayData } from "./ProductList";

export interface ProductsProps {
    productList: EndpointCatalogueProductListResponse;
    T: (data: LocalizedValue<string | undefined>) => string | undefined;
    order: Order;
    historyGo: (route: string) => void;
    overlayData: OverlayData;
}

const classNameBase = "_catalogue-category-products";

export const Products: React.FC<ProductsProps> = ({ productList, T, order, overlayData, historyGo }) => {
    const history = useHistory();

    const navigateToProduct = (productId: number, type: number) => {
        console.log("navigate");
        historyGo(`/catalogue/product?ProductId=${productId}&Type=${type}`);
        // history.push({ pathname: "/catalogue/product", search: `ProductId=${productId}&Type=${type}` });
    };

    return (
        <div className={`${classNameBase}__list`} id="productList">
            {productList.map(p => {
                const isInOrder = order.state.products.map(e => e.productId).includes(p.productId);
                return (
                    <div className={`${classNameBase}__product`} key={p.productId}>
                        <div className={`${classNameBase}__product-content`}>
                            <div className={`${classNameBase}__product-photo`} onClick={() => navigateToProduct(p.productId, p.productType.productTypeId)}>
                                {!!p.photoFilename ? (
                                    <img
                                        className={`${classNameBase}__product-photo-img`}
                                        src={GEAPP_API_CONFIG.photoUrl + p.photoFilename}
                                        alt={`Photo of ${T(p.productName)}`}
                                    />
                                ) : (
                                    <div className={`${classNameBase}__product-no-photo`}>photo_camera</div>
                                )}
                            </div>
                            <div className={`${classNameBase}__product-title`} onClick={() => navigateToProduct(p.productId, p.productType.productTypeId)}>
                                {T(p.productName)}
                            </div>
                            <div className={`${classNameBase}__product-ean`}>{p.ean}</div>
                            <div className={`${classNameBase}__product-type`}>{T(p.productType.label)}</div>
                            <div className={`${classNameBase}__product-buttons`}>
                                <button
                                    className={`button ${isInOrder ? "fill" : ""}`}
                                    onClick={() => {
                                        if (isInOrder) {
                                            order.removeProduct(p.productId);
                                        } else {
                                            order.addProduct({
                                                productId: p.productId,
                                                productName: p.productName,
                                                ean: p.ean,
                                                category: {
                                                    mainLabel: p.categoryMain.label,
                                                    secodnaryLabel: p.category.label
                                                },
                                                photoFilename: p.photoFilename,
                                                count: 1,
                                                productTypeLabel: p.productType.label
                                            });
                                        }
                                    }}
                                >
                                    {isInOrder ? T(t.deleteFromQuery) : T(t.addToQuery)}
                                </button>
                                <button onClick={() => navigateToProduct(p.productId, p.productType.productTypeId)} className={`button`}>
                                    {T(t.showProduct)}
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}

            {overlayData.show ? (
                <div className="_catalogue-product-list__overlay">
                    <div className="_catalogue-product-list__overlay-title">{overlayData.title}</div>
                    <div className="_catalogue-product-list__overlay-description">{overlayData.text}</div>
                </div>
            ) : null}
        </div>
    );
};
