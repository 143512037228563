import React from "react";

import { LocalizedValue } from "../../services/Language";

import Logo from "../../assets/logo_ge.png";

interface AboutProps {
    T: (data: LocalizedValue<string | undefined>) => string | undefined;
}

const About: React.FC<AboutProps> = ({ T }) => {
    return (
        <div className="_start-about">
            <div className="_start-about__container">
                <div className="_start-about__title">
                    <div className="_start-about__title-logo">
                        <img src={Logo} alt="GE" className="_start-about__title-logo-img" />
                    </div>
                    <div className="_start-about__title-text">{T({ pl: "O Nas", en: "About us" })}</div>
                </div>
                <div className="_start-about__text">
                    {T({
                        pl:
                            "GE Healthcare to dostawca innowacyjnych technologii i usług, które wyznaczają nowe standardy opieki medycznej.",
                        en:
                            "GE Healthcare provides innovative technology and services, which sets new standards of healthcare."
                    })}
                    <br />
                    <br />
                    {T({
                        pl: `Nasze szerokie doświadczenia w anestezjologii i intensywnej terapii,
                        monitorowaniu pacjenta, diagnostyce kardiologicznej I opiece okołoporodowej
                        umożliwiają naszym klientom na całym świecie zwiększenie dostępności i jakości opieki medycznej
                        przy jednoczesnym obniżaniu kosztów.`,
                        en: `Our broad experience in anesthesiology and intensive care, patient monitoring, 
                        cardiological diagnostic and perintal care enables our clients all around the world to increase availability and quality
                        of healthcare while reducing costs.`
                    })}
                </div>
                <a href="https://www.gehealthcare.com/" className="_start-about__button button">
                    {T({ pl: "Dowiedz się więcej", en: "Learn more" })}
                </a>
            </div>

            <div className="_start-about__container container-2">
                <div className="_start-about__title">
                    <div className="_start-about__title-text no-padding">
                        {T({ pl: "Dane kotnaktowe", en: "Contact us" })}
                    </div>
                </div>
                <div className="_start-about__text">
                    GE Medical Systems Polska Sp. z o.o.
                    <br />
                    ul. Wołoska 9,
                    <br />
                    02-583 Warsaw
                    <br />
                    <a href="mailto:akcesoria.medyczne@ge.com">akcesoria.medyczne@ge.com</a>
                </div>{" "}
            </div>
        </div>
    );
};

export default About;
