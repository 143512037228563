import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { BaseComponent } from "../utils/BaseComponent";

import { Products } from "./catalogue/ProductList/Products";

import "./Search.scss";
import { DataSourceParentStateGeneric, DataSource } from "../datasource/base/DataSource.base";
import { EndpointCatalogueProductSearch, EndpointCatalogueProductSearchResponse, endpointCatalogueProductSearch } from "../datasource/catalogue/ProductSearch";
import { OverlayData } from "./catalogue/ProductList/ProductList";

interface SearchProps {
    close: () => void;
}

interface SearchDatasources {
    productSearch: DataSourceParentStateGeneric<EndpointCatalogueProductSearchResponse>;
}

interface SearchState {
    datasource: SearchDatasources;
    searchValue: string;
    searchAttempt: boolean;
}

class Search extends BaseComponent<RouteComponentProps & SearchProps, SearchState> {
    state: SearchState = {
        datasource: {
            productSearch: {
                data: [],
                status: "idle"
            }
        },
        searchValue: "",
        searchAttempt: false
    };

    private epProductSearch = endpointCatalogueProductSearch();
    private dsProductSearch = new DataSource<EndpointCatalogueProductSearch, SearchDatasources, SearchState, Search>(
        this,
        "productSearch",
        this.epProductSearch
    );

    render() {
        return (
            <div className="search">
                <div className="search__header">
                    <div className="search__header-top">
                        <div className="search__header-title">Szukaj produktów</div>
                        <div className="search__header-close-button" onClick={() => this.props.close()}>
                            close
                        </div>
                    </div>
                    <div className="search__header-field">
                        <div className="search__header-field-input-wrapper">
                            <input
                                className="search__header-field-input"
                                value={this.state.searchValue}
                                onChange={e => this.setState({ searchValue: e.target.value })}
                                onKeyDown={e => this.onKeyDown(e)}
                            />
                        </div>
                        <button className="search__header-field-button" onClick={() => this.search()}>
                            search
                        </button>
                    </div>
                </div>
                <div className="search__results">
                    <Products
                        productList={this.state.datasource.productSearch.data}
                        T={v => this.T(v)}
                        order={this.context.order}
                        historyGo={route => {
                            this.props.close();
                            this.props.history.push(route);
                        }}
                        overlayData={this.overlayData}
                    />
                </div>
            </div>
        );
    }

    get overlayData(): OverlayData {
        if (this.state.searchAttempt) {
            return {
                show: true,
                title: "Wpisz min. 3 znaki"
            };
        }

        if (this.state.datasource.productSearch.status === "idle") {
            return {
                show: true,
                title: "Wpisz szukaną frazę"
            };
        }

        if (this.state.datasource.productSearch.status === "pending") {
            return {
                show: true,
                title: "Szukanie ..."
            };
        }

        if (this.state.datasource.productSearch.status === "error") {
            return {
                show: true,
                title: "Błąd wyszukiwania",
                text: "Spróbuj wyszukać ponownie"
            };
        }

        if (this.state.datasource.productSearch.status === "ready" && this.state.datasource.productSearch.data.length === 0) {
            return {
                show: true,
                title: "Nie znaleziono pasujących produktów"
            };
        }

        return {
            show: false
        };
    }

    private onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            this.search();
        }
    }

    private search() {
        const searchValue = this.state.searchValue.trim();
        if (searchValue.length < 3) {
            console.log("min 3 znaki");
            this.setState({ searchAttempt: true });
            return;
        }
        this.setState({ searchAttempt: false });

        this.dsProductSearch.makeRequest({ params: { searchValue: searchValue } });
    }
}

export default withRouter(Search);
