import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';

import {
    ProductListByCategoryItem,
    ProductListByCategoryParams,
    ProductDetailsParams
} from '../../../../../Api/types-client/src/areas/catalogue/Product.types';
import {ProductDetailsModel} from '../../../../../Api/types-client/src/areas/catalogue/ProductDetails.types';

export type EndpointCatalogueProductDetailsResponse = ProductDetailsModel;

export type EndpointCatalogueProductDetailsParams = ProductDetailsParams;

export class EndpointCatalogueProductDetails extends Endpoint<
    EndpointCatalogueProductDetailsParams,
    undefined,
    EndpointCatalogueProductDetailsResponse,
    undefined
> {}

export function endpointCatalogueProductDetails() {
    return new EndpointCatalogueProductDetails(
        {
            url: '/productDetails/details',
            method: 'GET',
            authorizationToken: false
        },
        undefined
    );
}
