import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { BaseComponent } from "../../utils/BaseComponent";
import App from "../../App";
import { Authorization } from "../../services/Authorization";

export interface EndpointConfigBase {
    url: string;
    method: "GET" | "POST";
}

export interface EndpointConfigNonAuthorized extends EndpointConfigBase {
    authorizationToken: false;
}

export interface EndpointConfigOptionalAuthorized extends EndpointConfigBase {
    authorizationToken: boolean;
}

export type EndpointConfig<TParent> = TParent extends EndpointParentComponent
    ? EndpointConfigOptionalAuthorized
    : EndpointConfigNonAuthorized;

export interface EndpointError {
    code: number;
    error?: string | Object;
}

export interface EndpointWrapperSuccess<TResponseData> {
    code: 200 | 201 | 204;
    data: TResponseData;
}

export interface EndpointWrapperError {
    code: 400 | 401 | 402 | 403 | 404 | 420 | 422 | 500;
    error: {
        message?: string;
        systemMessage?: string;
    };
}

export type EndpointWrapper<TResponseData> = EndpointWrapperSuccess<TResponseData> | EndpointWrapperError;

export type RequestPayload<TParams, TData> = TParams extends undefined
    ? TData extends undefined
        ? void
        : { data: TData }
    : TData extends undefined
    ? { params: TParams }
    : { params: TParams; data: TData };

export type EndpointParentComponent = BaseComponent<any, any, any> | App;
export type EndpointParent = EndpointParentComponent | undefined;

export type MakeRequestArguments<T> = T extends (payload: infer A) => any ? A : never;
export type EndpointPayloadByEndpoint<TEndpoint extends Endpoint<any, any, any, any>> = MakeRequestArguments<
    TEndpoint["makeRequest"]
>;

export type EndpointResponseByEndpoint<TEndpoint> = TEndpoint extends Endpoint<any, any, infer TResponse, any>
    ? TResponse
    : never;

export interface EndpointAuthHeaders {
    GESessionId: number;
    GESessionToken: string;
}

export class Endpoint<
    TParams = undefined,
    TData = undefined,
    TResponse = void,
    TParent extends EndpointParent = EndpointParentComponent
> {
    private config: EndpointConfig<TParent>;
    private parent: TParent;

    constructor(config: EndpointConfig<TParent>, parent: TParent) {
        this.config = config;
        this.parent = parent;
    }

    public makeRequest(payload: RequestPayload<TParams, TData>): Promise<TResponse> {
        return new Promise<{
            authHeaders: EndpointAuthHeaders | undefined;
            parentAuth: Authorization | undefined;
        }>((resolve, reject) => {
            if (!this.config.authorizationToken) {
                return resolve({ authHeaders: undefined, parentAuth: undefined });
            }
            if (!this.parent) {
                return reject("Parent not specified");
            }

            let parentAuth: Authorization | undefined;

            if (!!this.parent && !!this.parent.context && !!this.parent.context.auth) {
                parentAuth = (this.parent as BaseComponent<any, any, any>).context.auth;
            } else if (!!this.parent && !!(this.parent as App).appContext && !!(this.parent as App).appContext.auth) {
                parentAuth = (this.parent as App).appContext.auth;
            }

            if (!parentAuth) {
                return Promise.reject("Parent does not have auth");
            }

            resolve(parentAuth.getToken().then(authHeaders => ({ authHeaders: authHeaders, parentAuth: parentAuth })));
        }).then(({ authHeaders, parentAuth }) => {
            const axiosConfig: AxiosRequestConfig = {
                url: GEAPP_API_CONFIG.apiUrl + this.config.url,
                method: this.config.method,
                data: !!payload && (payload as any).data ? (payload as any).data : undefined,
                params: !!payload && (payload as any).params ? (payload as any).params : undefined,
                headers: { "Content-Type": "application/json", ...authHeaders }
            };

            return axios(axiosConfig)
                .then((response: AxiosResponse<EndpointWrapper<TResponse>>) => {
                    if (response.status === 200) {
                        if (response.data.code === 200 || response.data.code === 201 || response.data.code === 204) {
                            return response.data.data;
                        }
                        if (this.config.authorizationToken && parentAuth && response.data.code === 403) {
                            return parentAuth.logout().then(() => Promise.reject(response.data));
                        }
                        return Promise.reject(response.data);
                    } else {
                        return Promise.reject({
                            code: 500,
                            error: {
                                message: "Unknown error",
                                systemMessage: "Unknown axios error"
                            }
                        });
                    }
                })
                .catch(e => {
                    return Promise.reject(e);
                });
        });
    }
}
