import uuid from 'uuid/v4';
import { BaseComponent } from '../../utils/BaseComponent';
import { Endpoint, EndpointPayloadByEndpoint, EndpointResponseByEndpoint } from './Endpoint.base';

export type DataSourceStatus = 'idle' | 'pending' | 'ready' | 'error';

export interface DataSourceParentStateItem<TData> {
    data: TData | undefined;
    status: DataSourceStatus;
}

export interface DataSourceParentStateGeneric<TData> {
    data: TData;
    status: DataSourceStatus;
}

export type DataSourceParentStateDatasource<TParentStateDatasource> = {
    [K in keyof TParentStateDatasource]: DataSourceParentStateItem<any>;
};

export interface DataSourceParentState<TParentDataSource> {
    datasource: DataSourceParentStateDatasource<TParentDataSource>;
}

export class DataSource<
    TEndpoint extends Endpoint<any, any, any, any>,
    TParentStateDatasource,
    TParentState extends DataSourceParentState<TParentStateDatasource>,
    TParent extends BaseComponent<any, TParentState, any>
> {
    private parent: TParent;
    private stateKey: keyof TParentStateDatasource;
    private endpoint: TEndpoint;
    private lastRequestGuid: string | undefined;

    constructor(parent: TParent, stateKey: keyof TParentStateDatasource, endpoint: TEndpoint) {
        this.parent = parent;
        this.stateKey = stateKey;
        this.endpoint = endpoint;
    }

    public makeRequest(
        payload: EndpointPayloadByEndpoint<TEndpoint>
    ): Promise<EndpointResponseByEndpoint<TEndpoint>> {
        const requestGuid = uuid();
        console.log('creating request with guid: ' + requestGuid);
        this.lastRequestGuid = requestGuid;
        return this.setStatus('pending')
            .then(() => this.endpoint.makeRequest(payload as any))
            .then(response => {
                if (this.lastRequestGuid !== requestGuid) {
                    return Promise.reject('old request: ' + requestGuid);
                }
                return this.setData(response)
                    .then(() => this.setStatus('ready'))
                    .then(() => response);
            })
            .catch(e => {
                return (this.lastRequestGuid === requestGuid
                    ? this.setStatus('error')
                    : Promise.resolve()
                ).then(() => Promise.reject(e));
            });
    }

    private setStatus(status: DataSourceStatus): Promise<void> {
        return new Promise((resolve, reject) => {
            this.parent.setState(
                p => ({
                    datasource: {
                        ...p.datasource,
                        [this.stateKey]: {
                            ...p.datasource[this.stateKey],
                            status: status
                        }
                    }
                }),
                () => resolve()
            );
        });
    }

    private setData(data: EndpointResponseByEndpoint<TEndpoint>): Promise<void> {
        return new Promise((resolve, reject) => {
            this.parent.setState(
                p => ({
                    datasource: {
                        ...p.datasource,
                        [this.stateKey]: {
                            ...p.datasource[this.stateKey],
                            data: data
                        }
                    }
                }),
                () => resolve()
            );
        });
    }
}
