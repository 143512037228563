import { Endpoint, EndpointParentComponent } from "../base/Endpoint.base";

import { ProductListByCategoryItem, ProductListSearchParams } from "../../../../../Api/types-client/src/areas/catalogue/Product.types";

export type EndpointCatalogueProductSearchResponse = ProductListByCategoryItem[];

export type EndpointCatalogueProductSearchParams = ProductListSearchParams;

export class EndpointCatalogueProductSearch extends Endpoint<EndpointCatalogueProductSearchParams, undefined, EndpointCatalogueProductSearchResponse, undefined> {}

export function endpointCatalogueProductSearch() {
    return new EndpointCatalogueProductSearch(
        {
            url: "/catalogue/products/search",
            method: "GET",
            authorizationToken: false
        },
        undefined
    );
}
