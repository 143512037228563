import React from "react";
import { BaseComponent } from "../../utils/BaseComponent";

import "./Login.scss";

export default class Login extends BaseComponent {
    private login() {
        this.context.auth.login("name4", "haslo123");
    }
    render() {
        // return <div>Login <button onClick={() => this.login() }>login</button></div>;
        return (
            <div className="_auth-login">
                <div className="_auth-login__content">
                    <input type="text" placeholder="LOGIN" />
                    <input type="password" placeholder="PASS" />

                    <button className="button">Login</button>
                </div>
            </div>
        );
    }
}
