import React from "react";
import { Redirect, Route, Switch, Link } from "react-router-dom";

import { BaseComponent } from "../utils/BaseComponent";

import "./Routing.scss";

import ProductList from "./catalogue/ProductList/ProductList";
import DeviceList from "./catalogue/ProductList/DeviceList";
import ProductDetails from "./catalogue/Product/Product";
import ContractList from "./contract/Contract";
import Login from "./authorization/Login";
import Nav from "./Nav";
import Search from "./Search";
import OrderModal from "./orderModal/OrderModal";
import Start from "./start/Start";
import { DataSourceParentStateGeneric, DataSource } from "../datasource/base/DataSource.base";
import {
    EndpointCatalogueCategoryListResponse,
    EndpointCatalogueCategoryList,
    endpointCatalogueCategoryList
} from "../datasource/catalogue/CategoryList";
import Privacy from "./Privacy";
import Rodo from "./Rodo";
import PrivacyBanner from "./PrivacyBanner";
import { getCookie, setCookie } from "../utils/Cookies";

export interface RoutingDatasrouce {
    categoryList: DataSourceParentStateGeneric<EndpointCatalogueCategoryListResponse>;
}

interface RoutingState {
    searchOpen: boolean;
    datasource: RoutingDatasrouce;
    showCookiesInfo: boolean;
}

export default class Routing extends BaseComponent<{}, RoutingState> {
    state: RoutingState = {
        searchOpen: false,
        showCookiesInfo: getCookie("cookiesConfirmed") !== "true",
        datasource: {
            categoryList: {
                data: [],
                status: "idle"
            }
        }
    };

    private epCategoryList = endpointCatalogueCategoryList();
    private dsCategoryList = new DataSource<EndpointCatalogueCategoryList, RoutingDatasrouce, RoutingState, Routing>(
        this,
        "categoryList",
        this.epCategoryList
    );

    componentDidMount() {
        this.getCategories();
    }

    get isAuthorized() {
        return this.context.auth.isAuthorized;
    }

    render() {
        return (
            <div className="_routing">
                <Nav
                    searchToggle={() => {
                        this.setState({ searchOpen: true });
                        window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                />
                <Switch>
                    <Route
                        path="/catalogue/product"
                        render={props => (
                            <ProductDetails {...props} categoryList={this.state.datasource.categoryList} />
                        )}
                    />
                    <Route
                        path="/accessories"
                        render={props => <ProductList {...props} categoryList={this.state.datasource.categoryList} />}
                    />
                    <Route
                        path="/devices"
                        render={props => <DeviceList {...props} categoryList={this.state.datasource.categoryList} />}
                    />

                    {!this.isAuthorized ? <Route path="/login" component={Login} /> : null}
                    {this.context.auth.isAuthorized ? (
                        <Route path="/contracts" component={ContractList} />
                    ) : (
                        <Route path="/contracts" component={Login} />
                    )}

                    <Route path="/cookies" component={Privacy} />
                    <Route path="/rodo" component={Rodo} />
                    <Route path="/home" component={Start} />
                    <Redirect from="/*" to="/home" />
                </Switch>
                <div className="_routing__footer">
                    <p>
                        JB75050PL © 2020 GENERAL ELECTRIC COMPANY
                        <br />
                        <Link to="/cookies">{this.T({ pl: "Informacja o cookies", en: "Cookies policy" })}</Link>
                        <br />
                        <Link to="/rodo">{this.T({ pl: "Polityka RODO", en: "RODO policy" })}</Link>
                        <br />
                    </p>
                </div>
                {this.state.showCookiesInfo ? (
                    <PrivacyBanner
                        close={() => {
                            setCookie("cookiesConfirmed", "true", 1000);
                            this.setState(() => ({ showCookiesInfo: false }));
                        }}
                    />
                ) : null}
                {this.context.order.state.isOpen ? <OrderModal /> : null}
                {this.state.searchOpen ? <Search close={() => this.setState({ searchOpen: false })} /> : null}
            </div>
        );
    }

    private async getCategories() {
        this.dsCategoryList.makeRequest();
    }
}
