import React from "react";

import { Authorization } from "../services/Authorization";
import { Translation } from "../services/Language";
import { Order } from "../services/Order";

export interface AppContextType {
    auth: Authorization;
    lang: Translation;
    order: Order;
}

export const AppContext = React.createContext<AppContextType>({
    auth: null as any,
    lang: null as any,
    order: null as any
});
