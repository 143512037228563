import React from 'react';

import { AppContext } from '../context/AppContext';
import { LocalizedValue } from '../services/Language';

export class BaseComponent<TProps = {}, TState = {}, TSS = {}> extends React.Component<
    TProps,
    TState,
    TSS
> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    protected T<TData>(data: LocalizedValue<TData>): TData | undefined {
        return this.context.lang.T(data);
    }
}
