import React from "react";
import { Link } from "react-router-dom";
import { BaseComponent } from "../utils/BaseComponent";

export default class Privacy extends BaseComponent {
    render() {
        return (
            <div className="_privacy">
                <div className="_privacy__content">
        <div className="_privacy__title">{this.T({pl: `Polityka dotycząca cookies`, en: `Cookies Privacy Policy`})}</div>
                    <div className="_privacy__text">
                        <p>{this.T({pl: `W celu świadczenia usług na najwyższym poziomie stosujemy pliki cookies. Korzystanie z
                            naszej witryny oznacza, że będą one zamieszczane w Państwa urządzeniu. W każdym momencie
                            można dokonać zmiany ustawień Państwa przeglądarki. Dodatkowo, korzystanie z naszej witryny
                            oznacza akceptację przez Państwa{" "}`, en: `In order to provide services on the highest level we use cookie. Using our website means, the files will be installed to your device. However your browser settings can be changed anytime. Additionally, by using our website, the user consents to the `})}
                            
                            <Link to="/rodo">
                                {this.T({pl: `klauzuli przetwarzania danych osobowych udostępnionych drogą elektroniczną`, en: `processing of personal data provided electronically`})}
                                
                            </Link>
                            .
                        </p>
                        <p> {this.T({pl: `Dla Państwa wygody serwis akcesoria-ge.pl używa plików cookies po to, by dostosować serwis
                            do potrzeb użytkowników, a także w celach statystycznych. Pliki cookies (tzw. ciasteczka) to
                            niewielkie pliki tekstowe wysyłane przez odwiedzany serwis internetowy do urządzenia
                            internauty (komputer, smartfon itp.). Nie zawierają one żadnych danych osobowych.`, en: `For your comfort the akcesoria-ge.pl service uses cookies in order to adjust the service to the needs of users and to provide statistics. Cookies are small text files sent to the users device ( a smartphone, computer ) by the website they visited. They do not contain any sort of personal information.`})}
                            
                        </p>
        <p>{this.T({pl:`Serwis akcesoria-ge.pl stosuje dwa typy plików cookies:`, en: `akcesoria-ge.pl service uses two types of cookie files: `})}</p>
                        <ul>
                            <li>{this.T({pl:`wydajnościowe (zbierające informacje na temat sposobu używania witryny przez
                                odwiedzających, np. najczęściej odwiedzanych stron lub komunikatów o błędach itp.),`, en: `efficiency type ( they gather information about the way visitors use the website, e.g.  most frequently visited sites or most common error messages etc. )`})}
                                
                            </li>
                            <li>{this.T({pl:`funkcjonalne (zapisujące ustawienia użytkownika, np. język, wyrażone zgody itp.), -
                                cookies google-analytics.com – służą do prowadzenia statystyk dla witryny
                                akcesoria-ge.pl; dokładny sposób działania i politykę prywatności Google Analytics można
                                znaleźć `, en:`functional type ( the save users settings, e.g. the language, accepted agreements etc. ) - cookies google - analytics.com - their main purpose is to provide the akcesoria-ge.pl website with statistics; u can check Google Analytics service’s privacy policy and learn more how it works `})}
                                <a href="http://www.google.com/analytics/learn/privacy.html">{this.T({pl:`tutaj`, en:`here`})}</a>.
                            </li>
                        </ul>
                        <p> {this.T({pl:`W większości przeglądarek internetowych można: kasować pliki typu cookies z twardego dysku
                            komputera (z poziomu ustawień przeglądarki), zablokować wszystkie przysyłane pliki typu
                            cookies lub ustawić ostrzeżenie przed zapisaniem ich na dysku. Należy mieć na uwadze, że
                            zmiany ustawień tych plików polegające na ograniczeniu ich stosowania mogą wpłynąć na
                            niektóre funkcjonalności dostępne na stronach internetowych je stosujących np.
                            uniemożliwiając zalogowanie się na konto pocztowe. Brak zmiany tych ustawień oznacza
                            akceptację dla stosowania plików typu cookies.`, en: `In most browsers it’s possible to delete cookies from the computer’s hard drive ( using browsers settings ), block all cookie data flow or set a warning before saving them on the hard drive. By doing so, the user can experience a change in functionality of some of the websites services, e.g. user won’t be able to log into an email account. If settings are unchanged its equivalent to user accepting the usage of cookies.`})}
                            
                            <br />
                            <br />
                            {this.T({pl:`Poniżej wskazujemy sposób zmiany ustawień cookies w najbardziej popularnych wyszukiwarkach:`, en: `Down below, we will show how to change cookies settings in popular browsers:
`})}
                            <br />
                            <br />
                            <b>Google Chrome</b>
                            <br />
                            {this.T({pl:`Menu > Ustawienia > Pokaż ustawienia zaawansowane > Prywatność > Ustawienia treści > Pliki
                            cookies – należy wybrać odpowiednią opcję.`, en: `Menu > Settings > Show advanced > Privacy > Content settings > Cookies - choose a suitable option.`})}
                            <br />
                            <br />
                            <b>Internet Explorer</b>
                            <br />
                            {this.T({pl:`Menu > Narzędzia > Opcje internetowe > Prywatność – należy wybrać odpowiednią opcję.`, en: `Menu > Tools > Internet settings > Privacy  - choose a suitable option.`})}
                            <br />
                            <br />
                            <b>Mozilla Firefox</b>
                            <br />
                            {this.T({pl:`Menu > Opcje > Prywatność > Historia – należy wybrać odpowiednią opcję.`, en: `Menu > Options > Privacy > History - choose a suitable option.`})}
                            <br />
                            <br />
                            <b>Opera</b>
                            <br />
                           {this.T({pl:` Menu > Preferencje > Zaawansowane > Ciasteczka – należy wybrać odpowiednią opcję.`, en: `Menu > Preferences > Advanced > Cookies - choose a suitable option.`})}
                            <br />
                            <br />
                            <b>Safari</b>
                            <br />
                            {this.T({pl:`Menu > Preferencje > Prywatność > Pliki cookies – należy wybrać odpowiednią opcję.`, en: `Menu > Preferences > Privacy > Cookies - choose a suitable option.
`})}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
