import React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

import { BaseComponent } from "../utils/BaseComponent";

import logo from "../assets/logo_ge.png";
import T from "./Nav.json";
import "./Nav.scss";
import { TranslationLanguage } from "../services/Language";

interface NavProps {
    searchToggle: () => void;
}

interface NavState {
    isOpen: boolean;
}

class Nav extends BaseComponent<RouteComponentProps & NavProps, NavState> {
    state: NavState = {
        isOpen: false
    };

    get isOpen() {
        return this.state.isOpen;
    }

    private changeState() {
        this.setState(p => ({ isOpen: !p.isOpen }));
    }

    private closeNav() {
        this.setState({ isOpen: false });
    }

    private logout() {
        this.closeNav();
        this.context.auth.logout();
    }

    private changeLang(langauge: TranslationLanguage) {
        this.context.lang.setCurrentLanguage(langauge);
    }

    render() {
        return (
            <div className="_nav">
                <div className="_nav__center">
                    <div
                        className="_nav__logo"
                        onClick={() => {
                            this.props.history.push("/home");
                            this.closeNav();
                        }}
                    >
                        <img src={logo} alt="logo ge" className="_nav__logo-img" />
                    </div>
                    <div className="_nav__right">
                        <div className="_nav__right-button" onClick={() => this.changeState()}>
                            {this.isOpen ? "close" : "notes"}
                        </div>
                    </div>
                    <div className={`_nav__links ${this.isOpen ? "visible-sm" : ""}`}>
                        <div className="_nav__links-left">
                            <Link className="_nav__link" to="/accessories" onClick={() => this.closeNav()}>
                                {this.T(T.accessories)}
                            </Link>

                            <Link className="_nav__link" to="/devices" onClick={() => this.closeNav()}>
                                {this.T(T.devices)}
                            </Link>

                            {this.context.auth.isAuthorized ? (
                                <React.Fragment>
                                    <Link className="_nav__link" to="/contracts" onClick={() => this.closeNav()}>
                                        {this.T(T.contracts)}
                                    </Link>
                                    <Link className="_nav__link" to="/orders" onClick={() => this.closeNav()}>
                                        {this.T(T.orders)}
                                    </Link>
                                </React.Fragment>
                            ) : // <React.Fragment>
                            //     <Link className="_nav__link" to="/login" onClick={() => this.closeNav()}>
                            //         {this.T(T.login)}
                            //     </Link>
                            // </React.Fragment>
                            null}
                        </div>
                        <div className="_nav__links-right">
                            {this.context.auth.isAuthorized ? (
                                <button className="_nav__link" onClick={() => this.logout()}>
                                    {this.T(T.logout)}
                                </button>
                            ) : null}

                            <div className="_nav__link-order">
                                <div
                                    className="_nav__link-order-icon"
                                    onClick={() => {
                                        this.closeNav();
                                        this.props.searchToggle();
                                    }}
                                >
                                    <div className="_nav__link-order-icon-icon">search</div>
                                </div>
                                <div
                                    className="_nav__link-order-label"
                                    onClick={() => {
                                        this.closeNav();
                                        this.props.searchToggle();
                                    }}
                                >
                                    {this.T(T.search)}
                                </div>
                            </div>

                            <div className="_nav__link-order">
                                <div
                                    className="_nav__link-order-icon"
                                    onClick={() => {
                                        this.closeNav();
                                        this.context.order.openPanel();
                                    }}
                                >
                                    <div className="_nav__link-order-icon-icon">shopping_cart</div>
                                    <div className={`_nav__link-order-icon-count ${this.context.order.state.products.length > 0 ? "show" : ""}`}>
                                        {this.context.order.state.products.length}
                                    </div>
                                </div>
                                <div
                                    className="_nav__link-order-label"
                                    onClick={() => {
                                        this.closeNav();
                                        this.context.order.openPanel();
                                    }}
                                >
                                    {this.T(T.order)}
                                    {this.context.order.state.products.length > 0 ? ` (${this.context.order.state.products.length})` : ""}
                                </div>
                            </div>

                            <div className="_nav__link-langauge-switch">
                                <button
                                    className={`_nav__link lang ${this.context.lang.currentLanguage === "pl" ? "selected" : ""}`}
                                    onClick={() => this.changeLang("pl")}
                                >
                                    PL
                                </button>
                                |
                                <button
                                    className={`_nav__link lang ${this.context.lang.currentLanguage === "en" ? "selected" : ""}`}
                                    onClick={() => this.changeLang("en")}
                                >
                                    EN
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Nav);
