import App from "../App";

export type TranslationLanguage = "pl" | "en";

export interface LocalizedValue<T> {
    pl?: T;
    en?: T;
}

const localStorageLanguageKey = "GEAppLocalStorageLanguageKey";

export class Translation {
    private parent: App;

    constructor(parent: App) {
        this.parent = parent;
    }

    get currentLanguage(): TranslationLanguage {
        return this.parent.state.appContext.lang.language;
    }

    public setCurrentLanguage(language: TranslationLanguage): Promise<void> {
        return new Promise((resolve, reject) => {
            this.parent.setState(
                p => ({
                    appContext: {
                        ...p.appContext,
                        lang: {
                            ...p.appContext.lang,
                            language: language
                        }
                    }
                }),
                () => resolve()
            );
        }).then(() => {
            window.localStorage.setItem(localStorageLanguageKey, language);
        });
    }

    public loadLanguageFromLocalStorage() {
        const savedLanguage = window.localStorage.getItem(localStorageLanguageKey);

        if (!!savedLanguage && (savedLanguage === "pl" || savedLanguage === "en")) {
            this.setCurrentLanguage(savedLanguage);
        }
    }

    public T<TData = any>(data: LocalizedValue<TData>): TData | undefined {
        return data[this.currentLanguage] ? data[this.currentLanguage] : undefined;
    }

    private saveLanguage() {}
}
