import React from "react";

import { BaseComponent } from "../../utils/BaseComponent";

import { endpointCatalogueNewRequest } from "../../datasource/catalogue/NewRequest";

import T from "./OrderModal.json";

import "./OrderModal.scss";
import { Endpoint } from "../../datasource/base/Endpoint.base";
import { DataSourceStatus } from "../../datasource/base/DataSource.base";

interface OrderModalState {
    form: {
        email: string;
        name: string;
        company: string;
        telephone: string;
    };
    datasource: DataSourceStatus;
    firstCheck: boolean;
}

export default class OrderModal extends BaseComponent<{}, OrderModalState> {
    state: OrderModalState = {
        form: {
            email: "",
            name: "",
            company: "",
            telephone: ""
        },
        firstCheck: false,
        datasource: "idle"
    };

    private setField(name: keyof OrderModalState["form"], value: string) {
        this.setState(p => ({ form: { ...p.form, [name]: value } }));
    }

    get validEmail(): boolean {
        return new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, "").test(this.state.form.email);
    }

    private send() {
        this.setState({ firstCheck: true });
        if (!this.validEmail) {
            return;
        }

        this.setState({ datasource: "pending" });
        endpointCatalogueNewRequest()
            .makeRequest({
                data: {
                    guest: {
                        firstName: this.state.form.name,
                        email: this.state.form.email,
                        telephone: this.state.form.telephone,
                        companyName: this.state.form.company,
                        languageCode: this.context.lang.currentLanguage,
                        lastName: ""
                    },
                    products: this.context.order.state.products.map(p => ({
                        productId: p.productId,
                        quantity: p.count
                    }))
                }
            })
            .then(() => {
                this.setState({ datasource: "ready" });
                this.context.order.clearOrder();
            })
            .catch(e => {
                console.log(e);
                this.setState({ datasource: "error" });
            });
    }

    render() {
        return (
            <div className="_order-modal">
                <div className="_order-modal__bg" onClick={() => this.context.order.closePanel()}></div>
                <div className="_order-modal__content">
                    <div className="_order-modal__header">
                        <div className="_order-modal__header-title">{this.T(T.title)}</div>
                        <div className="_order-modal__header-close" onClick={() => this.context.order.closePanel()}>
                            close
                        </div>
                    </div>
                    <div className="_order-modal__scroll">
                        {this.context.order.state.products.map(p => (
                            <div className="_order-modal__product" key={p.productId}>
                                <div className="_order-modal__product-photo">
                                    {!!p.photoFilename && p.photoFilename !== "" ? (
                                        <img src={GEAPP_API_CONFIG.photoUrl + p.photoFilename} alt="" />
                                    ) : (
                                        <div className="_order-modal__product-photo-icon">photo_camera</div>
                                    )}
                                </div>
                                <div className="_order-modal__product-right">
                                    <div className="_order-modal__product-info">
                                        <div className="_order-modal__product-name">{this.T(p.productName)}</div>
                                        <div className="_order-modal__product-ean">{p.ean}</div>
                                    </div>
                                    <div className="_order-modal__product-count">
                                        <input
                                            value={p.count === 0 ? "" : p.count}
                                            onChange={e =>
                                                this.context.order.changeProductCount(p.productId, e.target.value)
                                            }
                                            onBlur={() => {
                                                if (p.count < 1) {
                                                    this.context.order.changeProductCount(p.productId, "1");
                                                }
                                            }}
                                        />
                                    </div>
                                    <div
                                        className="_order-modal__product-remove"
                                        onClick={() => this.context.order.removeProduct(p.productId)}
                                    >
                                        delete
                                    </div>
                                </div>
                            </div>
                        ))}
                        {this.context.order.state.products.length === 0 ? (
                            <div className="_order-modal__info-empty">{this.T(T.emptyInfo)}</div>
                        ) : (
                            <div className="_order-modal__form">
                                <div className="_order-modal__form-label">EMAIL*</div>
                                <input
                                    type="text"
                                    value={this.state.form.email}
                                    onChange={e => this.setField("email", e.target.value)}
                                />
                                {this.state.firstCheck && !this.validEmail ? (
                                    <div className="_order-moda__form-error">{this.T(T.incorrectEmail)}</div>
                                ) : null}

                                <div className="_order-modal__form-label">{this.T(T.fullName)}</div>
                                <input
                                    type="text"
                                    value={this.state.form.name}
                                    onChange={e => this.setField("name", e.target.value)}
                                />

                                <div className="_order-modal__form-label">{this.T(T.company)}</div>
                                <input
                                    type="text"
                                    value={this.state.form.company}
                                    onChange={e => this.setField("company", e.target.value)}
                                />

                                <div className="_order-modal__form-label">{this.T(T.telephone)}</div>
                                <input
                                    type="text"
                                    value={this.state.form.telephone}
                                    onChange={e => this.setField("telephone", e.target.value)}
                                />

                                <button className="button" onClick={() => this.send()}>
                                    {this.T(T.send)}
                                </button>
                            </div>
                        )}

                        {this.state.datasource !== "idle" ? (
                            <div className="_order-modal__overlay">
                                {this.T(T.datasourceStatus[this.state.datasource])}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}
