import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';

import { CategoryMainModel } from '../../../../../Api/types-client/src/areas/catalogue/Category.types';

export type EndpointCatalogueCategoryListResponse = CategoryMainModel[];

export class EndpointCatalogueCategoryList extends Endpoint<
    undefined,
    undefined,
    EndpointCatalogueCategoryListResponse,
    undefined
> {}

export function endpointCatalogueCategoryList() {
    return new EndpointCatalogueCategoryList(
        {
            url: '/catalogue/category/tree',
            method: 'GET',
            authorizationToken: false
        },
        undefined
    );
}
