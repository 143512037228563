import { Endpoint, EndpointParentComponent } from "../base/Endpoint.base";

import {
    ProductListByCategoryItem,
    ProductListByCategoryParams
} from "../../../../../Api/types-client/src/areas/catalogue/Product.types";

export interface GuestBaseModel {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    companyName: string;
    languageCode: string;
}

export interface RequestProductBaseModel {
    productId: number;
    quantity: number;
}

export interface EndpointCatalogueNewRequestData {
    guest: GuestBaseModel;
    products: RequestProductBaseModel[];
}

export type EndpointCatalogueProductListParams = ProductListByCategoryParams;

export class EndpointCatalogueNewRequest extends Endpoint<
    undefined,
    EndpointCatalogueNewRequestData,
    undefined,
    undefined
> {}

export function endpointCatalogueNewRequest() {
    return new EndpointCatalogueNewRequest(
        {
            url: "/request/new",
            method: "POST",
            authorizationToken: false
        },
        undefined
    );
}
