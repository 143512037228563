import React from "react";

import Slide1PL from "../../assets/start-sliders/slide-1-pl.jpg";
import Slide1EN from "../../assets/start-sliders/slide-1-en.jpg";
import Slide2PL from "../../assets/start-sliders/slide-2-pl.jpg";
import Slide2EN from "../../assets/start-sliders/slide-2-en.jpg";
import Slide3PL from "../../assets/start-sliders/slide-3-pl.jpg";
import Slide3EN from "../../assets/start-sliders/slide-3-en.jpg";
import Slide4PL from "../../assets/start-sliders/slide-4-pl.jpg";
import Slide4EN from "../../assets/start-sliders/slide-4-en.jpg";
import Slide5PL from "../../assets/start-sliders/slide-5-pl.jpg";
import Slide5EN from "../../assets/start-sliders/slide-5-pl.jpg";
import Empty from "../../assets/start-sliders/empty.jpg";

interface SliderProps {
    lang: "pl" | "en";
    current: number;
    next: (direction: 1 | -1) => void;
    goTo: (index: number) => void;
}

const Slider: React.FC<SliderProps> = ({ lang, current, next, goTo }) => {
    return (
        <div className="_start-slider">
            <div className="_start-slider__container">
                <div className="_start-slider__arrow-container left" onClick={() => next(-1)}>
                    <div className="_start-slider__arrow-icon">keyboard_arrow_left</div>
                </div>
                <div className="_start-slider__arrow-container right" onClick={() => next(1)}>
                    <div className="_start-slider__arrow-icon">keyboard_arrow_right</div>
                </div>
                <div className="_start-slider__dots">
                    <div
                        className={`_start-slider__dot ${current === 0 ? "active" : ""}`}
                        onClick={() => goTo(0)}
                    ></div>
                    <div
                        className={`_start-slider__dot ${current === 1 ? "active" : ""}`}
                        onClick={() => goTo(1)}
                    ></div>
                    <div
                        className={`_start-slider__dot ${current === 2 ? "active" : ""}`}
                        onClick={() => goTo(2)}
                    ></div>
                    <div
                        className={`_start-slider__dot ${current === 3 ? "active" : ""}`}
                        onClick={() => goTo(3)}
                    ></div>
                    <div
                        className={`_start-slider__dot ${current === 4 ? "active" : ""}`}
                        onClick={() => goTo(4)}
                    ></div>
                </div>
                <div className="_start-slider__shape">
                    <img src={Empty} className="_start-slider__shape-img" />
                </div>
                <div className="_start-slider__slides">
                    {current === 0 && lang === "pl" ? (
                        <div className={`_start-slider__slide slide-1-pl`}>
                            <a href="https://akcesoria-ge.pl/catalogue/product?ProductId=723">
                                <img src={Slide1PL} alt="" className="_start-slider__slide-img" />
                            </a>
                        </div>
                    ) : null}
                    {current === 0 && lang === "en" ? (
                        <div className={`_start-slider__slide slide-1-en`}>
                            <a href="https://akcesoria-ge.pl/catalogue/product?ProductId=723">
                                <img src={Slide1EN} alt="" className="_start-slider__slide-img" />
                            </a>
                        </div>
                    ) : null}

                    {current === 1 && lang === "pl" ? (
                        <div className={`_start-slider__slide slide-2-pl`}>
                            <a href="https://www.gehealthcare.co.uk/products/carescape-one">
                                <img src={Slide2PL} alt="" className="_start-slider__slide-img" />
                            </a>
                        </div>
                    ) : null}
                    {current === 1 && lang === "en" ? (
                        <div className={`_start-slider__slide slide-2-pl`}>
                            <a href="https://www.gehealthcare.co.uk/products/carescape-one">
                                <img src={Slide2EN} alt="" className="_start-slider__slide-img" />
                            </a>
                        </div>
                    ) : null}

                    {current === 2 && lang === "pl" ? (
                        <div className={`_start-slider__slide slide-2-en`}>
                            <a href="https://www.gehealthcare.co.uk/products/healthcare-digital">
                                <img
                                    src={Slide3PL}
                                    alt="CARESCAPE ONE Wszystko w jednym"
                                    className="_start-slider__slide-img"
                                />
                            </a>
                        </div>
                    ) : null}
                    {current === 2 && lang === "en" ? (
                        <div className={`_start-slider__slide slide-2-en`}>
                            <a href="https://www.gehealthcare.co.uk/products/healthcare-digital">
                                <img
                                    src={Slide3EN}
                                    alt="CARESCAPE ONE Wszystko w jednym"
                                    className="_start-slider__slide-img"
                                />
                            </a>
                        </div>
                    ) : null}

                    {current === 3 && lang === "pl" ? (
                        <div className={`_start-slider__slide slide-2-en`}>
                            <a href="http://www3.gehealthcare.pl/pl-pl/serwis">
                                <img
                                    src={Slide4PL}
                                    alt="CARESCAPE ONE Wszystko w jednym"
                                    className="_start-slider__slide-img"
                                />
                            </a>
                        </div>
                    ) : null}
                    {current === 3 && lang === "en" ? (
                        <div className={`_start-slider__slide slide-2-en`}>
                            <a href="https://www.gehealthcare.co.uk/services/services">
                                <img
                                    src={Slide4EN}
                                    alt="CARESCAPE ONE Wszystko w jednym"
                                    className="_start-slider__slide-img"
                                />
                            </a>
                        </div>
                    ) : null}

                    {current === 4 && lang === "pl" ? (
                        <div className={`_start-slider__slide slide-2-en`}>
                            <a href="https://www.gehealthcare.com/specialties/cardiology-solutions">
                                <img
                                    src={Slide5PL}
                                    alt="CARESCAPE ONE Wszystko w jednym"
                                    className="_start-slider__slide-img"
                                />
                            </a>
                        </div>
                    ) : null}
                    {current === 4 && lang === "en" ? (
                        <div className={`_start-slider__slide slide-2-en`}>
                            <a href="https://www.gehealthcare.com/specialties/cardiology-solutions">
                                <img
                                    src={Slide5EN}
                                    alt="CARESCAPE ONE Wszystko w jednym"
                                    className="_start-slider__slide-img"
                                />
                            </a>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default Slider;
