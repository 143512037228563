import React from "react";
import { Link } from "react-router-dom";
import { BaseComponent } from "../utils/BaseComponent";

interface Props {
    close: () => void;
}

export default class PrivacyBanner extends BaseComponent<Props> {
    render() {
        return (
            <div className="_privacy-banner">
                <div className="_privacy-banner__text">
                    {this.T({
                        pl:
                            "Ta strona wykorzystuje pliki cookies w celach statystycznych. Więcej informacji znajdziesz ",
                        en: "This site uses cookies for statistic purposes. More info can be found "
                    })}{" "}
                    <Link to="/cookies">{this.T({ pl: "tutaj", en: "here" })}</Link>.
                </div>
                <div className="_privacy-banner__close" onClick={() => this.props.close()}>
                    close
                </div>
            </div>
        );
    }
}
