import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';
import { Session, Identity } from './Authorization.types';
import { BaseComponent } from '../../utils/BaseComponent';

export interface EndpointAuthorizationRefreshSessionData {
    identityId: number;
    refreshToken: string;
}

export interface EndpointAuthorizationRefreshSessionResponse extends Session {}

export class EndpointAuthorizationRefreshSession extends Endpoint<
    undefined,
    EndpointAuthorizationRefreshSessionData,
    EndpointAuthorizationRefreshSessionResponse,
    undefined
> {}

export function endpointAuthorizationRefreshSession() {
    return new EndpointAuthorizationRefreshSession(
        {
            url: '/authorize/refreshSession',
            method: 'POST',
            authorizationToken: false
        },
        undefined
    );
}
