import moment from 'moment';

import App from '../App';
import { Identity, Session } from '../datasource/authorization/Authorization.types';
import {
    endpointAuthorizationLogin,
    EndpointAuthorizationLogin
} from '../datasource/authorization/Login';
import {
    endpointAuthorizationCheckSession,
    EndpointAuthorizationCheckSession
} from '../datasource/authorization/CheckSesssion';
import { EndpointAuthHeaders } from '../datasource/base/Endpoint.base';
import {
    EndpointAuthorizationRefreshSession,
    endpointAuthorizationRefreshSession
} from '../datasource/authorization/RefreshSession';
import {
    EndpointAuthorizationLogout,
    endpointAuthorizationLogout
} from '../datasource/authorization/Logout';
import { promised } from 'q';

const localStorageKey = 'GEAppClientSessionStorageKey';

export class Authorization {
    private parent: App;
    private epLogin: EndpointAuthorizationLogin;
    private epCheckSession: EndpointAuthorizationCheckSession;
    private epRefreshSession: EndpointAuthorizationRefreshSession;
    private epLogout: EndpointAuthorizationLogout;

    constructor(parent: App) {
        this.parent = parent;
        this.epLogin = endpointAuthorizationLogin();
        this.epCheckSession = endpointAuthorizationCheckSession(this.parent);
        this.epRefreshSession = endpointAuthorizationRefreshSession();
        this.epLogout = endpointAuthorizationLogout();
    }

    get storage() {
        return this.parent.state.appContext.auth;
    }

    get isAuthorized() {
        return !!this.storage.identity;
    }

    public login(login: string, password: string): Promise<void> {
        return this.epLogin
            .makeRequest({ data: { login: login, password: password } })
            .then(response => {
                return this.setIdentityState(response);
            });
    }

    public checkSession(): Promise<boolean> {
        return this.epCheckSession
            .makeRequest()
            .then(() => {
                return true;
            })
            .catch(e => {
                return false;
            });
    }

    public refreshToken(): Promise<void> {
        console.log('refresh token called');
        return new Promise((resolve, reject) => {
            if (
                !this.storage.identity ||
                !this.storage.identity.identityId ||
                !this.storage.identity.refreshToken
            ) {
                return reject('Identity not found');
            }
            this.epRefreshSession
                .makeRequest({
                    data: {
                        identityId: this.storage.identity.identityId,
                        refreshToken: this.storage.identity.refreshToken
                    }
                })
                .then(response => {
                    this.setIdentityState(undefined, response).then(() => resolve());
                })
                .catch(e => {
                    resolve(this.logout().then(() => Promise.reject(e)));
                });
        });
    }

    public logout(): Promise<void> {
        if (
            !this.storage.identity ||
            !this.storage.identity.identityId ||
            !this.storage.identity.refreshToken
        ) {
            return this.clearIdentity();
        }

        return this.epLogout
            .makeRequest({
                data: {
                    identityId: this.storage.identity.identityId,
                    refreshToken: this.storage.identity.refreshToken
                }
            })
            .then(() => this.clearIdentity())
            .catch(e => {
                return this.clearIdentity().finally(() => Promise.resolve());
            });
    }

    public getToken(): Promise<EndpointAuthHeaders> {
        return new Promise((resolve, reject) => {
            if (!this.isAuthorized || !this.storage.identity) {
                return reject('User not authorized');
            }

            if (moment().isSameOrBefore(this.storage.identity.session.expireDate, 'minute')) {
                console.log('expire date is in future');
                return resolve();
            } else {
                return resolve(this.refreshToken());
            }
        }).then(() => {
            if (!this.isAuthorized || !this.storage.identity) {
                return Promise.reject('User not authorized');
            }
            const headers: EndpointAuthHeaders = {
                GESessionId: this.storage.identity.session.sessionId,
                GESessionToken: this.storage.identity.session.sessionToken
            };

            return headers;
        });
    }

    public loadSavedIdentity(): Promise<void> {
        return this.readFromLocalStorage()
            .then(identity => {
                return this.setIdentityState(identity);
            })
            .then(() => this.checkSession())
            .then(r => console.log(r));
    }

    private setIdentityState(identity?: Identity, session?: Session): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!!identity) {
                this.parent.setState(
                    p => ({
                        appContext: {
                            ...p.appContext,
                            auth: {
                                ...p.appContext.auth,
                                identity: identity
                            }
                        }
                    }),
                    () => {
                        resolve();
                        this.saveToLocalStorage(identity);
                    }
                );
                this.saveToLocalStorage(identity);
            } else if (!!session && !!this.storage.identity) {
                this.parent.setState(
                    p => ({
                        appContext: {
                            ...p.appContext,
                            auth: {
                                ...p.appContext.auth,
                                identity: { ...p.appContext.auth.identity!, session: session }
                            }
                        }
                    }),
                    () => {
                        resolve();
                        if (!!this.storage.identity) {
                            this.saveToLocalStorage({
                                identityId: this.storage.identity.identityId,
                                refreshToken: this.storage.identity.refreshToken,
                                session: session
                            });
                        }
                    }
                );
            } else {
                this.parent.setState(
                    p => ({
                        appContext: {
                            ...p.appContext,
                            auth: {
                                ...p.appContext.auth,
                                identity: undefined
                            }
                        }
                    }),
                    () => {
                        resolve();
                        this.saveToLocalStorage(undefined);
                    }
                );
            }
        });
    }

    private clearIdentity(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.parent.setState(
                p => ({
                    appContext: {
                        ...p.appContext,
                        auth: {
                            ...p.appContext.auth,
                            identity: undefined
                        }
                    }
                }),
                () => {
                    resolve();
                    this.saveToLocalStorage(undefined);
                }
            );
        });
    }

    private saveToLocalStorage(identity: Identity | undefined) {
        if (!identity) {
            localStorage.removeItem(localStorageKey);
        } else {
            localStorage.setItem(localStorageKey, JSON.stringify(identity));
        }
    }

    private readFromLocalStorage(): Promise<Identity> {
        return new Promise((resolve, reject) => {
            const lsData = localStorage.getItem(localStorageKey);

            try {
                if (!lsData) {
                    return reject('Local Storage is empty');
                }
                const data: Identity = JSON.parse(lsData);
                if (
                    !data.identityId ||
                    !data.refreshToken ||
                    !data.session ||
                    !data.session.sessionId ||
                    !data.session.expireDate ||
                    !data.session.sessionToken
                ) {
                    return reject('Local Storage data is incomplete');
                }

                return resolve(data);
            } catch (e) {
                return reject('Local Storage value is unreadable');
            }
        });
    }
}
