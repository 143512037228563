import React from "react";
import { LocalizedValue } from "../../services/Language";
import { Link } from "react-router-dom";

interface InfoProps {
    extendedInstruction: boolean;
    lang: "pl" | "en";
    toggleInstruction: () => void;
    T: (data: LocalizedValue<string | undefined>) => string | undefined;
}

const Info: React.FC<InfoProps> = ({ extendedInstruction, toggleInstruction, T, lang }) => {
    return (
        <div className="_start-info">
            <div className="_start-info__container">
                <div className="_start-info__text">
                    {T({ pl: "Szanowni Państwo!", en: "Welcome!" })}
                    <br />
                    <br />
                    {T({
                        pl: `Zapraszamy do korzystania z naszego katalogu online akcesoriów medycznych, który ułatwi przeglądanie
                    naszego portfolio oraz umożliwi złożenie zapytania do naszego przedstawiciela w celu otrzymania
                    spersonalizowanej oferty cenowej zawierającej interesujące Państwa artykuły.`,
                        en: `We are pleased to invite You to browse our online catalog of accessories,
                        which will enable You to submit an inquiry to our local sales representative to
                        receive a personalized price offer for the accessories you are interested in.`
                    })}
                </div>
                <div className="_start-info__show-instruction" onClick={() => toggleInstruction()}>
                    <span className="_start-info__show-instruction-icon">
                        {extendedInstruction ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                    </span>
                    {extendedInstruction
                        ? T({ pl: "Schowaj instrukcję", en: "Hide Instruction" })
                        : T({ pl: "Pokaż instrukcję", en: "Show instruction" })}
                </div>
                {extendedInstruction ? (
                    <div className="_start-info__instruction">
                        <div className="_start-info__instruction-title">
                            {T({ pl: "Przeglądanie portfolio", en: "Browsing catalog" })}
                        </div>
                        <div className="_start-info__instruction-text">
                            {T({
                                pl: `Najpierw należy wybrać kategorię na stronie startowej,
                            po której będziemy przeglądać artykuły (po urządzeniach lub po rodzaju akcesoriów).
                            Następnie należy kliknąć w przycisk “POKAŻ” znajdujący się pod zdjęciem interesującego nas produktu.`,
                                en: `First, choose whether You want to browse our accessories portfolio by compatible devices or accessories categories.
                                Then select the category you are interested in. To show the product details page click the 'Show' button.`
                            })}
                        </div>
                        <ul>
                            <li>
                                {T({
                                    pl: `W opisie produktu znajduje się kategoria “podobne akcesoria”
                            wskazująca na produkty o tym samym przeznaczeniu, ale innych parametrach`,
                                    en: `'Similar Accessories' and ‘Other sizes’ bar visible on product details page shows products with the same designation but different parameters`
                                })}
                            </li>
                            <li>
                                {T({
                                    pl: `W opisie produktu znajduje się kategoria “kompatybilne urządzenia”
                                kierująca do opisów urządzeń firmy GE, które są kompatybilne z przeglądanym artykułem`,
                                    en: `'Compatible devices' bar visible on the product details page shows GE Healthcare products that are compatible with the current product.`
                                })}
                            </li>
                            <li>
                                {T({
                                    pl: `W opisie produktu znajduje się kategoria “kompatybilne akcesoria” wskazująca
                                na produkty kompatybilne z aktualnie przeglądanym artykułem`,
                                    en: `'Compatible Accessories' bar visible on the product details page shows accessories that are compatible with the current product.`
                                })}
                            </li>
                        </ul>
                        <div className="_start-info__instruction-title">
                            {T({ pl: "Wysyłanie zapytania", en: "Sending Inquiry" })}
                        </div>
                        {lang === "pl" ? (
                            <div className="_start-info__instruction-text">
                                Po kliknięciu w przycisk “DODAJ” znajdujący się pod zdjęciem produktu zostanie on
                                automatycznie przeniesiony do koszyka znajdującego się w prawym górnym rogu strony. Po
                                skompletowaniu całego zapytania należy przejść do koszyka klikając w ikonę{" "}
                                <span className="_start-info__instruction-text-icon">shopping_cart</span> i wypełnić
                                formularz kontaktowe umożliwiający kontakt naszego przedstawciela w celu przesłania
                                Państwu spersonalizowanej oferty oraz kliknąć przycik “WYŚLIJ ZAPYTANIE”. Nasz zespół
                                skontaktuje się z Państwem najszybciej jak to możliwe.
                            </div>
                        ) : (
                            <div className="_start-info__instruction-text">
                                Clicking button 'Add' visible below each product will add that product to the inquiry
                                list. The current state of Yours inquiry list will be shown after clicking{" "}
                                <span className="_start-info__instruction-text-icon">shopping_cart</span> icon visible
                                in the top right corner or in the dropdown menu. When all required products are added to
                                the inquiry list fill the contact form and send it by clicking the 'Send Inquiry'
                                button. Our local sales representative will contact you as soon as possible.
                            </div>
                        )}
                    </div>
                ) : null}
                <div className="_start-info__browse">
                    <Link to="/devices" className="button">
                        {T({ pl: "Przeglądaj urządzenia", en: "Browse machines" })}
                    </Link>
                    <Link to="/accessories" className="button">
                        {T({ pl: "Przeglądaj akcesoria", en: "Browse accessories" })}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Info;
