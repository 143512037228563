import { Endpoint, EndpointParentComponent } from '../base/Endpoint.base';
import { Session, Identity } from './Authorization.types';
import { BaseComponent } from '../../utils/BaseComponent';

export interface EndpointAuthorizationLoginData {
    login: string;
    password: string;
}

export interface EndpointAuthorizationLoginResponse extends Identity {}

export class EndpointAuthorizationLogin extends Endpoint<
    undefined,
    EndpointAuthorizationLoginData,
    EndpointAuthorizationLoginResponse,
    undefined
> {}

export function endpointAuthorizationLogin() {
    return new EndpointAuthorizationLogin(
        {
            url: '/authorize/login',
            method: 'POST',
            authorizationToken: false
        },
        undefined
    );
}
