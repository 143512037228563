import App from "../App";
import { LocalizedValue } from "./Language";

export interface OrderProduct {
    productId: number;
    productName: LocalizedValue<string>;
    ean: string;
    category: {
        mainLabel: LocalizedValue<string>;
        secodnaryLabel: LocalizedValue<string>;
    };
    photoFilename: string;
    count: number;
    productTypeLabel: LocalizedValue<string>;
}

export interface OrderState {
    products: OrderProduct[];
    isOpen: boolean;
}

const LOCALSTORAGE_KEY_ORDER_SERVICE = "GEAppLocalStorageOrderService";

export class Order {
    private _app: App;

    constructor(app: App) {
        this._app = app;
        // this.getProductsFromLocalStorage();
    }

    get state() {
        return this._app.state.appContext.order;
    }

    public addProduct(product: OrderProduct) {
        this.setState(p => {
            p.products.push(product);
            return p;
        }).then(() => this.saveProductsToLocalStorage());
    }

    public removeProduct(productId: number) {
        this.setState(p => {
            const index = p.products.findIndex(e => e.productId === productId);
            if (index >= 0 && index < p.products.length) {
                p.products.splice(index, 1);
            }
            return p;
        }).then(() => this.saveProductsToLocalStorage());
    }

    public clearOrder() {
        this.setState(p => ({ ...p, products: [] })).then(() => this.saveProductsToLocalStorage());
    }

    public changeProductCount(productId: number, productCount: string) {
        this.setState(p => ({
            ...p,
            products: p.products.map(product => {
                if (product.productId === productId) {
                    product.count = Number.isNaN(parseInt(productCount)) ? 0 : parseInt(productCount);
                }
                return product;
            })
        })).then(() => this.saveProductsToLocalStorage());
    }

    public getProductsFromLocalStorage() {
        const dataRaw = window.localStorage.getItem(LOCALSTORAGE_KEY_ORDER_SERVICE);
        if (!dataRaw) {
            return;
        }

        try {
            const data: OrderProduct[] = JSON.parse(dataRaw);
            this.setState(p => ({ ...p, products: data }));
        } catch (e) {
            console.log(e);
        }
    }

    public openPanel() {
        this.setState(p => ({ ...p, isOpen: true }));
    }

    public closePanel() {
        this.setState(p => ({ ...p, isOpen: false }));
    }

    private saveProductsToLocalStorage() {
        window.localStorage.setItem(LOCALSTORAGE_KEY_ORDER_SERVICE, JSON.stringify(this.state.products));
    }

    private setState(modifier: (s: OrderState) => OrderState): Promise<void> {
        return new Promise((resolve, reject) => {
            this._app.setState(
                p => ({
                    appContext: { ...p.appContext, order: { ...p, ...modifier(p.appContext.order) } }
                }),
                () => resolve()
            );
        });
    }
}
